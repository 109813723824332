import compose from 'ramda/src/compose';
import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import map from 'ramda/src/map';
import toPairs from 'ramda/src/toPairs';
import zipObj from 'ramda/src/zipObj';
import keys from 'ramda/src/keys';
import forEach from 'ramda/src/forEach';
import sort from 'ramda/src/sort';
import sortBy from 'ramda/src/sortBy';
import prop from "ramda/src/prop";
import striptags from 'striptags';

import Defaults from 'mangools-commons/lib/constants/Defaults';
import UrlService from 'mangools-commons/lib/services/UrlService';

import {
    parseFloatWithNAFallback,
    parseFloatWithNVFallback,
    parseIntWithNAFallback,
    parseIntWithNVFallback,
    parseStringWithNAFallback,
} from 'sources/parsers/commons';

import { BRAND, NAKED, OTHER } from 'constants/AnchorTypes';
import TrafficSourceTypes from 'constants/TrafficSourceTypes';
import UrlTypes from 'constants/UrlTypes';
import { MAJESTIC_MAX_TOP_RANK } from 'constants/Other';


const isPresent = item => !isNil(item) && !isEmpty(item);
const isTopRankHistoryPresent = item => isPresent(item.topRankHistory);
const isTopRankPresent = item => isPresent(item.topRank);
const isTopRankSecretPresent = item => isPresent(item.topRankSecret);
const isFacebookHistoryPresent = item => isPresent(item.fbHistory);
const isMajesticHistoryPresent = item => isPresent(item.majesticHistory);
const isMajesticPresent = item => isPresent(item.majestic);
const isMozPresent = item => isPresent(item.moz);
const isSimilarWebPresent = item => isPresent(item.similarWeb);

function parseUrlType({ rawUrlType, inputUrl }) {
    // NOTE:
    // As the GetItemIndexInfo Majestic endpoint does not support Path* UrlType
    // but we want to support it as further section will work.
    if (UrlService.isPathUrl(inputUrl)) {
        return UrlTypes.PATH_URL;
    } else {
        switch (rawUrlType) {
            case 3: {
                return UrlTypes.EXACT_URL;
            }
            case 2: {
                return UrlTypes.SUBDOMAIN;
            }
            case 1: {
                return UrlTypes.ROOT_DOMAIN;
            }
            default: {
                return UrlTypes.ROOT_DOMAIN;
            }
        }
    }
}

function parseFacebookData(data) {
    let description = Defaults.NOT_AVAILABLE;
    let imageUrl = Defaults.NOT_AVAILABLE;
    let shares = Defaults.NOT_AVAILABLE;
    let title = Defaults.NOT_AVAILABLE;

    if (!isNil(data) && !isEmpty(data)) {
        shares = parseIntWithNAFallback(data.l);

        if (!isNil(data.og) && !isEmpty(data.og)) {
            description = striptags(parseStringWithNAFallback(data.og.description));
            title = striptags(parseStringWithNAFallback(data.og.title));
            imageUrl = parseStringWithNAFallback(data.og.image);
        }
    }

    return {
        description,
        imageUrl,
        shares,
        title,
    };
}

function parseHistoryData(data) {
    const convert = compose(sortBy(prop('date')), map(zipObj(['date', 'value'])), toPairs);

    return map(
        item => ({
            date: item.date,
            value: isNil(item.value) ? null : parseInt(item.value, 10),
        }),
        convert(data),
    );
}

function parseMajesticHistoryData(data) {
    const convert = compose(map(zipObj(['date', 'value'])), toPairs);

    return map(
        item => ({
            date: item.date,
            value: parseIntWithNAFallback(item.value.refIps),
        }),
        convert(data),
    );
}

function parseMajesticStatus(rawStatus) {
    if (!isNil(rawStatus)) {
        return rawStatus === 'Found';
    } else {
        return true; // Handling cached results without this value
    }
}

/* eslint-disable max-len */
export const parseMainOverview = (rawData, inputUrl) => {
    const fbData = parseFacebookData(rawData.fb);

    return {
        topRank: {
            current: isTopRankPresent(rawData)
                ? parseIntWithNAFallback(rawData.topRank)
                : Defaults.NOT_AVAILABLE,
            change: Defaults.NOT_AVAILABLE,
            history: isTopRankHistoryPresent(rawData) ? parseHistoryData(rawData.topRankHistory) : [],
        },
        citationFlow: isMajesticPresent(rawData)
            ? parseIntWithNAFallback(rawData.majestic.CitationFlow)
            : Defaults.NOT_AVAILABLE,
        dataAvailable: isMajesticPresent(rawData) ? parseMajesticStatus(rawData.majestic.Status) : true,
        domainAuthority: isMozPresent(rawData) ? parseIntWithNAFallback(rawData.moz.pda) : Defaults.NOT_AVAILABLE,
        facebook: {
            current: fbData.shares,
            history: isFacebookHistoryPresent(rawData) ? parseHistoryData(rawData.fbHistory) : [],
            title: fbData.title,
        },
        meta: {
            urlType: isMajesticPresent(rawData)
                ? parseUrlType({ rawUrlType: rawData.majestic.ItemType, inputUrl })
                : UrlTypes.ROOT_DOMAIN,
        },
        pageAuthority: isMozPresent(rawData) ? parseIntWithNAFallback(rawData.moz.upa) : Defaults.NOT_AVAILABLE,
        refIPs: {
            current: isMajesticPresent(rawData)
                ? parseIntWithNAFallback(rawData.majestic.RefIPs)
                : Defaults.NOT_AVAILABLE,
            history: isMajesticHistoryPresent(rawData) ? parseMajesticHistoryData(rawData.majesticHistory) : [],
        },
        trustFlow: isMajesticPresent(rawData)
            ? parseIntWithNAFallback(rawData.majestic.TrustFlow)
            : Defaults.NOT_AVAILABLE,
    };
};
/* eslint-enable max-len */

function parseMonthlySearchVolumes(rawVolumes) {
    return rawVolumes.map(item => ({
        count: parseIntWithNVFallback(item[2]),
        month: parseInt(item[1], 10),
        year: parseInt(item[0], 10),
    }));
}

function parseKeywords(data) {
    return data.map(item => ({
        cpc: parseFloatWithNVFallback(item.cpc),
        id: item._id || item.kw, // eslint-disable-line no-underscore-dangle
        keyword: item.kw,
        monthlySearchVolumes: !isNil(item.msv) ? parseMonthlySearchVolumes(item.msv) : [],
        ppc: parseIntWithNVFallback(item.ppc),
        rank: parseIntWithNVFallback(item.seo),
        searchVolume: parseIntWithNAFallback(item.sv),
    }));
}

function parseSourceDistValue(value, zeroFallback = false) {
    const parsedValue = parseFloatWithNAFallback(value);

    if (parsedValue !== Defaults.NOT_AVAILABLE) {
        const percent = parsedValue * 100;
        return parseFloat(percent.toFixed(2), 10);
    } else {
        return zeroFallback === true ? 0 : parsedValue;
    }
}

function parseTrafficGeoDistribution(data) {
    return data.map(item => ({
        countryCode: !isNil(item.countryCode) ? item.countryCode.toLowerCase() : Defaults.NOT_AVAILABLE,
        countryLabel: parseStringWithNAFallback(item.countryLabel),
        countryNumericCode: parseIntWithNAFallback(item.country),
        trafficShare: parseSourceDistValue(item.current),
        trafficShareChange: parseSourceDistValue(item.change),
    }));
}

function parseTrafficTopRankTimeOnSite(value) {
    const seconds = parseIntWithNAFallback(value);

    if (seconds !== Defaults.NOT_AVAILABLE) {
        const minutes = parseInt(seconds / 60, 10);
        const minutesString = minutes < 10 ? `0${minutes}` : minutes.toString();

        const remSeconds = seconds % 60;
        const remSecondsString = remSeconds < 10 ? `0${remSeconds}` : remSeconds.toString();

        return `${minutesString}:${remSecondsString}`;
    } else {
        return Defaults.NOT_AVAILABLE;
    }
}

/* eslint-disable max-len */
export const parseTrafficOverview = rawData => ({
    engagement: {
        bounceRate: {
            value: isTopRankPresent(rawData)
                ? parseFloatWithNAFallback(rawData.topRank.bouncePercent.current)
                : Defaults.NOT_AVAILABLE,
            changePercent: isTopRankPresent(rawData)
                ? parseFloatWithNAFallback(rawData.topRank.bouncePercent.change)
                : Defaults.NOT_AVAILABLE,
        },
        avgTimeOnSite: {
            value: isTopRankPresent(rawData)
                ? parseTrafficTopRankTimeOnSite(rawData.topRank.timeOnSite.current)
                : Defaults.NOT_AVAILABLE,
            changePercent: isTopRankPresent(rawData)
                ? parseStringWithNAFallback(rawData.topRank.timeOnSite.change)
                : Defaults.NOT_AVAILABLE,
        },
        pagesPerVisit: {
            value: isTopRankPresent(rawData)
                ? parseStringWithNAFallback(rawData.topRank.pageviewsPerVisitor.current)
                : Defaults.NOT_AVAILABLE,
            changePercent: isTopRankPresent(rawData)
                ? parseStringWithNAFallback(rawData.topRank.pageviewsPerVisitor.change)
                : Defaults.NOT_AVAILABLE,
        },
    },
    geoDistribution: isSimilarWebPresent(rawData)
        ? parseTrafficGeoDistribution(rawData.similarWeb.topCountryShares)
        : [],
    keywords: {
        organic: isPresent(rawData.organicKeywords) ? parseKeywords(rawData.organicKeywords) : [],
        paid: isPresent(rawData.paidKeywords) ? parseKeywords(rawData.paidKeywords) : [],
    },
    sourceDistribution: [
        {
            source: TrafficSourceTypes.DIRECT,
            value: isSimilarWebPresent(rawData)
                ? parseSourceDistValue(rawData.similarWeb.trafficSources.direct, true)
                : 0,
        },
        {
            source: TrafficSourceTypes.REFERRAL,
            value: isSimilarWebPresent(rawData)
                ? parseSourceDistValue(rawData.similarWeb.trafficSources.referrals, true)
                : 0,
        },
        {
            source: TrafficSourceTypes.ORGANIC,
            value: isSimilarWebPresent(rawData)
                ? parseSourceDistValue(rawData.similarWeb.trafficSources.search, true)
                : 0,
        },
        {
            source: TrafficSourceTypes.SOCIAL,
            value: isSimilarWebPresent(rawData)
                ? parseSourceDistValue(rawData.similarWeb.trafficSources.social, true)
                : 0,
        },
        {
            source: TrafficSourceTypes.PAID,
            value: isSimilarWebPresent(rawData)
                ? parseSourceDistValue(rawData.similarWeb.trafficSources.display, true)
                : 0,
        },
        {
            source: TrafficSourceTypes.EMAIL,
            value: isSimilarWebPresent(rawData)
                ? parseSourceDistValue(rawData.similarWeb.trafficSources.mail, true)
                : 0,
        },
    ],
    traffic: {
        history: isSimilarWebPresent(rawData)
            ? parseHistoryData(rawData.similarWeb.engagement.weeklyTrafficNumbers)
            : [],
        totalVisits: isSimilarWebPresent(rawData)
            ? parseIntWithNAFallback(rawData.similarWeb.engagement.totalLastMonthVisits)
            : Defaults.NOT_AVAILABLE,
        totalVisitsChangePercent: isSimilarWebPresent(rawData)
            ? parseSourceDistValue(rawData.similarWeb.engagement.totalRelativeChange)
            : Defaults.NOT_AVAILABLE,
    },
});
/* eslint-enable max-len */

export const parseCompetitorsOverview = rawData => {
    const items = rawData.competitors;

    return items.map(item => ({
        topRank: parseIntWithNAFallback(item.topRank),
        domain: item.domain,
        facebookShares: parseIntWithNAFallback(item.fb),
        refIPs: parseIntWithNAFallback(item.refIps),
        sharedKeywords: parseIntWithNAFallback(item.score),
    }));
};

// Specific comparator for url sorting.
// Returns a negative number if the first value is smaller (is http or www),
// a positive number if it's larger, and zero if they are equal
/* eslint-disable no-lonely-if */
function urlComparator(a, b) {
    if (a.match(/^https?:\/\/(www\.)/) && b.match(/^https?:\/\//)) {
        // 1. A is www B is non-www -> A is smaller
        return -1;
    } else if (a.match(/^https?:\/\//) && b.match(/^https?:\/\/(www\.)/)) {
        // 2. A is non-www B is www -> A is larger
        return 1;
    } else {
        // 3. Both A and B are www or are non-www
        // Check for https vs. http
        if (a.match(/^https:\/\//) && b.match(/^http:\/\//)) {
            // 3.1 A is https and B is http -> A is larger
            return 1;
        } else if (a.match(/^http:\/\//) && b.match(/^https:\/\//)) {
            // 3.2 A is http and B is https -> A is smaller
            return -1;
        } else {
            // 3.3 Both A and B are http or https they are the same
            return 0;
        }
    }
}
/* eslint-enable no-lonely-if */

export const parseTopContentOverview = rawData => {
    const items = rawData.topContent;

    // 0. Parse rawData
    const data = items.map(item => {
        const fbData = parseFacebookData(item.fb);

        return {
            description: fbData.description,
            facebookShares: fbData.shares === Defaults.NOT_AVAILABLE ? Defaults.NO_VALUE : fbData.shares,
            imageUrl: fbData.imageUrl,
            isImage: UrlService.isImageUrl(item.url),
            refDomains: parseIntWithNVFallback(item.refDomains),
            title: fbData.title,
            url: item.url,
        };
    });

    // 1. Generate map of url's
    const urlMap = {};

    data.reduce((resultObject, item) => {
        resultObject[item.url] = item; // eslint-disable-line no-param-reassign
        return resultObject;
    }, urlMap);

    // 2. Get the urls as array and sort it the way that
    // http version is before https and www is before non-www
    // so we can do combining in one run.
    const urls = keys(urlMap);
    const sortedUrls = sort(urlComparator, urls);

    // 3. Combine http/www data with https if available
    // or just www with http when https not available
    forEach(url => {
        if (url.match(/^https:\/\/(www\.)/)) {
            // HTTPS with WWW link
            const httpsLink = url.replace(/^https:\/\/(www\.)/, 'https://');
            const httpsObj = urlMap[httpsLink];

            if (!isNil(httpsObj) && url !== httpsLink) {
                // https link obj exists - combine ref domains
                const originalObj = urlMap[url];

                // Add up ref domains, but handle N/A
                if (httpsObj.refDomains !== Defaults.NOT_AVAILABLE) {
                    if (originalObj.refDomains !== Defaults.NOT_AVAILABLE) {
                        httpsObj.refDomains += originalObj.refDomains;
                    }
                } else if (originalObj.refDomains !== Defaults.NOT_AVAILABLE) {
                    httpsObj.refDomains = originalObj.refDomains;
                }

                // Mark original obj that it was combined and should be later ommited
                originalObj.combined = true;
                originalObj.combinedInto = httpsObj.url;
            }
        } else if (url.match(/^http:\/\/(www\.)/)) {
            // HTTP with WWW link
            const httpLink = url.replace(/^http:\/\/(www\.)/, 'http://');
            const httpObj = urlMap[httpLink];

            if (!isNil(httpObj) && url !== httpLink) {
                // https link obj exists - combine ref domains
                const originalObj = urlMap[url];

                // Add up ref domains, but handle N/A
                if (httpObj.refDomains !== Defaults.NOT_AVAILABLE) {
                    if (originalObj.refDomains !== Defaults.NOT_AVAILABLE) {
                        httpObj.refDomains += originalObj.refDomains;
                    }
                } else if (originalObj.refDomains !== Defaults.NOT_AVAILABLE) {
                    httpObj.refDomains = originalObj.refDomains;
                }

                // Mark original obj that it was combined and should be later ommited
                originalObj.combined = true;
                originalObj.combinedInto = httpObj.url;
            }
        } else if (url.match(/^http:\/\//)) {
            // http without www link
            const httpsLink = url.replace(/^http:\/\//, 'https://');
            const httpsObj = urlMap[httpsLink];

            if (!isNil(httpsObj) && url !== httpsLink) {
                // https link obj exists - combine ref domains
                const originalObj = urlMap[url];

                // Add up ref domains, but handle N/A
                if (httpsObj.refDomains !== Defaults.NOT_AVAILABLE) {
                    if (originalObj.refDomains !== Defaults.NOT_AVAILABLE) {
                        httpsObj.refDomains += originalObj.refDomains;
                    }
                } else if (originalObj.refDomains !== Defaults.NOT_AVAILABLE) {
                    httpsObj.refDomains = originalObj.refDomains;
                }

                // Mark original obj that it was combined and should be later ommited
                originalObj.combined = true;
                originalObj.combinedInto = httpsObj.url;
            }
        }
    }, sortedUrls);

    // 3. Convert urlMap back to array
    const convert = compose(map(zipObj(['url', 'obj'])), toPairs);

    const convertedData = map(
        item => ({
            combined: item.obj.combined,
            combinedInto: item.obj.combinedInto,
            description: item.obj.description,
            facebookShares: item.obj.facebookShares,
            imageUrl: item.obj.imageUrl,
            isImage: item.obj.isImage,
            refDomains: item.obj.refDomains,
            title: item.obj.title,
            url: item.url,
        }),
        convert(urlMap),
    );

    // 4. Filter combined items
    const filteredData = convertedData.filter(item => item.combined !== true);
    return filteredData;
};

function parseTopRefDomains(data) {
    return data.map(item => {
        const topRank = parseInt(item.topRank, 10);

        return {
            topRank: topRank === Defaults.NO_VALUE ? MAJESTIC_MAX_TOP_RANK : topRank,
            backlinkCount: parseIntWithNAFallback(item.matchedLinks),
            citationFlow: parseIntWithNAFallback(item.citationFlow),
            domain: item.domain,
            trustFlow: parseIntWithNAFallback(item.trustFlow),
        };
    });
}

function parseAnchorType(rawType) {
    const type = rawType.split('.')[0]; // TODO: Remove after second level is removed

    switch (type) {
        case 'brand': {
            return BRAND.value;
        }
        case 'naked': {
            return NAKED.value;
        }
        case 'other':
        default: {
            return OTHER.value;
        }
    }
}

function parseTopAnchors(data) {
    return data.map(item => ({
        anchor: item.anchorText,
        deletedLinks: parseIntWithNAFallback(item.deletedLinks),
        noFollowLinks: parseIntWithNAFallback(item.noFollowLinks),
        refDomains: parseIntWithNAFallback(item.refDomains),
        totalLinks: parseIntWithNAFallback(item.totalLinks),
        type: parseAnchorType(item.class),
    }));
}

function parseMajesticCalendarHistoryData(data) {
    return data.map(item => ({
        date: item.Date,
        new: parseIntWithNAFallback(item.NewLinks),
        deleted: parseIntWithNAFallback(item.LostLinks),
    }));
}

// function parseMajesticBackLinkHistoryData(data) {
//     const dates = keys(data.totalLinks);

//     const result = dates.map(date => ({
//         date,
//         new: parseIntWithNAFallback(data.totalLinks[date]),
//         deleted: parseIntWithNAFallback(data.deleted[date])
//     }));

//     return result.filter(item => item.new > 0 || item.deleted > 0);
// }

/* eslint-disable max-len */
export const parseBacklinkProfileOverview = rawData => {
    const haveAnchors = !isNil(rawData.majesticAnchors) && !isEmpty(rawData.majesticAnchors);
    const haveMajestic = isMajesticPresent(rawData);
    const haveMajesticHistory = !isNil(rawData.majesticBackLinkCalendar) && !isEmpty(rawData.majesticBackLinkCalendar);
    // const haveMajesticHistoryLong = !isNil(rawData.majesticBackLinkHistory)
    //     && !isEmpty(rawData.majesticBackLinkHistory);
    const haveRefDomains = !isNil(rawData.majesticRefDomains) && !isEmpty(rawData.majesticRefDomains);

    return {
        history: haveMajesticHistory ? parseMajesticCalendarHistoryData(rawData.majesticBackLinkCalendar) : [],
        // history: haveMajesticHistoryLong ? parseMajesticBackLinkHistoryData(rawData.majesticBackLinkHistory) : [],
        refDomains: haveMajestic ? parseIntWithNAFallback(rawData.majestic.RefDomains) : Defaults.NOT_AVAILABLE,
        refIPs: haveMajestic ? parseIntWithNAFallback(rawData.majestic.RefIPs) : Defaults.NOT_AVAILABLE,
        refSubnets: haveMajestic ? parseIntWithNAFallback(rawData.majestic.RefSubNets) : Defaults.NOT_AVAILABLE,
        topAnchors: haveAnchors ? parseTopAnchors(rawData.majesticAnchors) : [],
        topRefDomains: haveRefDomains ? parseTopRefDomains(rawData.majesticRefDomains) : [],
        totalDeletedLinks: haveMajestic
            ? parseIntWithNAFallback(rawData.majestic.NonUniqueLinkTypeDeleted)
            : Defaults.NOT_AVAILABLE,
        totalFrameLinks: haveMajestic
            ? parseIntWithNAFallback(rawData.majestic.NonUniqueLinkTypeFrame)
            : Defaults.NOT_AVAILABLE,
        totalImageLinks: haveMajestic
            ? parseIntWithNAFallback(rawData.majestic.NonUniqueLinkTypeImageLink)
            : Defaults.NOT_AVAILABLE,
        totalLinks: haveMajestic
            ? parseIntWithNAFallback(rawData.majestic.TotalNonUniqueLinks)
            : Defaults.NOT_AVAILABLE,
        totalNoFollowLinks: haveMajestic
            ? parseIntWithNAFallback(rawData.majestic.NonUniqueLinkTypeNoFollow)
            : Defaults.NOT_AVAILABLE,
        totalRedirectLinks: haveMajestic
            ? parseIntWithNAFallback(rawData.majestic.NonUniqueLinkTypeRedirect)
            : Defaults.NOT_AVAILABLE,
        totalTextLinks: haveMajestic
            ? parseIntWithNAFallback(rawData.majestic.NonUniqueLinkTypeTextLink)
            : Defaults.NOT_AVAILABLE,
    };
};
/* eslint-enable max-len */

function parseAudienceInterests(data) {
    return data.map(item => ({
        label: item.name,
        value: parseIntWithNAFallback(item.w),
    }));
}

function parseAudienceDistribution(data) {
    return data.map(item => ({
        label: item.name,
        value: parseFloatWithNAFallback(item.current),
    }));
}

/* eslint-disable max-len */
export const parseAudienceOverview = rawData => ({
    interests: isSimilarWebPresent(rawData) ? parseAudienceInterests(rawData.similarWeb.topics) : [],
    genderDistribution: isTopRankSecretPresent(rawData)
        ? parseAudienceDistribution(rawData.topRankSecret.demographics.gender)
        : [],
    educationDistribution: isTopRankSecretPresent(rawData)
        ? parseAudienceDistribution(rawData.topRankSecret.demographics.education)
        : [],
    browsingLocationDistribution: isTopRankSecretPresent(rawData)
        ? parseAudienceDistribution(rawData.topRankSecret.demographics.browsingLocation)
        : [],
});
/* eslint-enable max-len */

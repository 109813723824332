import React from 'react';

function PanelItemPreloader() {
    return (
        <div className="mg-results-td uk-flex uk-flex-middle uk-padding-remove">
            <div className="uk-flex mg-preloader-inline uk-flex-middle mg-padding-0-30 uk-width-1-1">
                <div className="uk-width-6-10 uk-height-1-1 mg-preloader-block" style={{ marginBottom: '3px' }} />
            </div>
        </div>
    );
}

PanelItemPreloader.propTypes = {};

export default PanelItemPreloader;

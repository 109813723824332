import update from 'immutability-helper';

import ActionTypes from 'constants/ActionTypes';
import { TOP_ANCHORS, TOP_CONTENT, TOP_REF_DOMAINS } from 'constants/SortingColumns';
import SortingTypes from 'constants/SortingTypes';
import { ALL } from 'constants/AnchorTypes';

const initialState = {
    dominantColorRgb: {
        red: 255,
        green: 255,
        blue: 255,
    },
    filtering: {
        topAnchors: {
            selectedAnchorType: ALL,
        },
    },
    sorting: {
        topAnchors: {
            column: TOP_ANCHORS.SHARE,
            type: SortingTypes.DESC,
        },
        topContent: TOP_CONTENT.REF_DOMAINS,
        topRefDomains: {
            column: TOP_REF_DOMAINS.TOP_RANK,
            type: SortingTypes.ASC,
        },
    },
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_DASHBOARD_DOMINANT_COLOR_SET: {
            return update(state, {
                dominantColorRgb: { $set: action.payload },
            });
        }
        case ActionTypes.UI_DASHBOARD_TOP_ANCHORS_SELECTED_ANCHOR_TYPE_SET: {
            return update(state, {
                filtering: {
                    topAnchors: {
                        selectedAnchorType: { $set: action.payload },
                    },
                },
            });
        }
        case ActionTypes.UI_DASHBOARD_TOP_ANCHORS_SORTING_SETTINGS_SET: {
            return update(state, {
                sorting: {
                    topAnchors: {
                        column: { $set: action.payload.column },
                        type: { $set: action.payload.type },
                    },
                },
            });
        }
        case ActionTypes.UI_DASHBOARD_TOP_REF_DOMAINS_SORTING_SETTINGS_SET: {
            return update(state, {
                sorting: {
                    topRefDomains: {
                        column: { $set: action.payload.column },
                        type: { $set: action.payload.type },
                    },
                },
            });
        }
        case ActionTypes.UI_DASHBOARD_TOP_CONTENT_SORTING_COLUMN_SET: {
            return update(state, {
                sorting: {
                    topContent: { $set: action.payload },
                },
            });
        }
        case ActionTypes.UI_DASHBOARD_RESET: {
            // NOTE: Not resetting the color as it has neet animation transition
            return update(state, {
                filtering: { $set: initialState.filtering },
                sorting: { $set: initialState.sorting },
            });
        }
        default: {
            return state;
        }
    }
};

export default dashboardReducer;

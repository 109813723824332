import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';

import Defaults from 'mangools-commons/lib/constants/Defaults';

class MathService {
    static diffPercent({ reference, value }) {
        if (reference === Defaults.NOT_AVAILABLE || value === Defaults.NOT_AVAILABLE) {
            return Defaults.NOT_AVAILABLE;
        } else if (reference === 0) {
            if (value > 0) {
                return 100;
            } else {
                return 0;
            }
        } else {
            return ((value - reference) / reference) * 100;
        }
    }

    static getPercent({ value, total, decimals = 2 }) {
        const ratio = total > 0 ? value / total : 0;
        const percent = ratio * 100;
        return parseFloat(percent.toFixed(decimals), 10);
    }

    static getPercentString({ value, total, decimals = 2 }) {
        const percent = MathService.getPercent({ value, total });
        return `${percent.toFixed(decimals)}%`;
    }

    static toPercentString(decimal, fixed = 0) {
        return `${(decimal * 100).toFixed(fixed)}%`;
    }

    static sum({ data, field }) {
        if (!isEmpty(data) && !isNil(field)) {
            const sum = data.reduce((acc, item) => {
                const value = item[field];

                if (!isNil(value) && value !== Defaults.NOT_AVAILABLE) {
                    acc += value; // eslint-disable-line no-param-reassign
                }

                return acc;
            }, 0);

            return sum;
        } else {
            return 0;
        }
    }
}

export default MathService;

import ActionTypes from 'constants/ActionTypes';

export const closeAll = () => ({
    type: ActionTypes.UI_ALL_CLOSE,
});

export const showAccessDeniedMessage = () => ({
    type: ActionTypes.UI_MESSAGES_ACCESS_DENIED_SHOW,
});

export const closeAccessDeniedMessage = () => ({
    type: ActionTypes.UI_MESSAGES_ACCESS_DENIED_CLOSE,
});

export const showNeedToSignInMessage = () => ({
    type: ActionTypes.UI_MESSAGES_NEED_TO_SIGN_IN_SHOW,
});

export const closeNeedToSignInMessage = () => ({
    type: ActionTypes.UI_MESSAGES_NEED_TO_SIGN_IN_CLOSE,
});

export const showFailureMessage = ({ details, header }) => ({
    type: ActionTypes.UI_MESSAGES_FAILURE_SHOW,
    error: false,
    payload: { details, header },
});

export const closeFailureMessage = () => ({
    type: ActionTypes.UI_MESSAGES_FAILURE_CLOSE,
});

export const setNavigatedInternally = () => ({
    type: ActionTypes.UI_MISC_NAVIGATED_INTERNALLY_SET,
});

export const receivedErrorNotificationAction = (message, config) => ({
    type: ActionTypes.UI_MISC_NOTIFICATIONS_ERROR_SHOW,
    error: false,
    payload: { message, config },
});

export const receivedInfoNotificationAction = (message, config) => ({
    type: ActionTypes.UI_MISC_NOTIFICATIONS_INFO_SHOW,
    error: false,
    payload: { message, config },
});

export const requestedCloseAllNotificationsAction = () => ({
    type: ActionTypes.UI_MISC_NOTIFICATIONS_CLOSE_ALL,
});

export const closeHelpDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_HELP_CLOSE,
});

export const showHelpDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_HELP_SHOW,
});

export const showExportDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_EXPORT_SHOW,
});

export const closeExportDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_EXPORT_CLOSE,
});

export const setOnlineStatus = ({ onlineStatus }) => ({
    type: ActionTypes.UI_MISC_ONLINE_STATUS_SET,
    error: false,
    payload: {
        onlineStatus,
    },
});

export const showNoConnectionMessage = () => ({
    type: ActionTypes.UI_MESSAGES_NO_CONNECTION_SHOW,
});

export const closeNoConnectionMessage = () => ({
    type: ActionTypes.UI_MESSAGES_NO_CONNECTION_CLOSE,
});

export const showHistoryPanel = () => ({
    type: ActionTypes.UI_PANELS_HISTORY_SHOW,
});

export const closeHistoryPanel = () => ({
    type: ActionTypes.UI_PANELS_HISTORY_CLOSE,
});

export const resetDashboard = () => ({
    type: ActionTypes.UI_DASHBOARD_RESET,
});

export const setDominantColorRgb = ({ red, green, blue }) => ({
    type: ActionTypes.UI_DASHBOARD_DOMINANT_COLOR_SET,
    error: false,
    payload: { red, green, blue },
});

export const closeTopLevelAnnouncementMessage = () => ({
    type: ActionTypes.UI_MESSAGES_TOP_LEVEL_ANNOUNCEMENT_CLOSE,
});

export const showLoggedOutMessage = () => ({
    type: ActionTypes.UI_MESSAGES_LOGGED_OUT_SHOW,
});

export const closeLoggedOutMessage = () => ({
    type: ActionTypes.UI_MESSAGES_LOGGED_OUT_CLOSE,
});

export const setNewVersionNotificationShown = () => ({
    type: ActionTypes.UI_MISC_NEW_VERSION_NOTIFICATION_SHOWN_SET,
});

export const showUrlTypeInfoMessage = () => ({
    type: ActionTypes.UI_MESSAGES_URL_TYPE_INFO_SHOW,
});

export const closeUrlTypeInfoMessage = () => ({
    type: ActionTypes.UI_MESSAGES_URL_TYPE_INFO_CLOSE,
});

export const setTopRefDomainsSortingSettings = ({ column, type }) => ({
    type: ActionTypes.UI_DASHBOARD_TOP_REF_DOMAINS_SORTING_SETTINGS_SET,
    error: false,
    payload: {
        column,
        type,
    },
});

export const setTopAnchorsSortingSettings = ({ column, type }) => ({
    type: ActionTypes.UI_DASHBOARD_TOP_ANCHORS_SORTING_SETTINGS_SET,
    error: false,
    payload: {
        column,
        type,
    },
});

export const setTopContentSortingColumn = column => ({
    type: ActionTypes.UI_DASHBOARD_TOP_CONTENT_SORTING_COLUMN_SET,
    error: false,
    payload: column,
});

export const setTopAnchorsSelectedAnchorType = anchorType => ({
    type: ActionTypes.UI_DASHBOARD_TOP_ANCHORS_SELECTED_ANCHOR_TYPE_SET,
    error: false,
    payload: anchorType,
});

export const showAppPanel = () => ({
    type: ActionTypes.UI_PANELS_APP_SHOW,
});

export const closeAppPanel = () => ({
    type: ActionTypes.UI_PANELS_APP_CLOSE,
});

export const setColorScheme = payload => ({
    type: ActionTypes.UI_MISC_COLOR_SCHEME_SET,
    payload,
});

import React from 'react';
import { bool, func, string } from 'prop-types';
import { connect } from 'react-redux';

import AccessDeniedMessage from 'components/messages/AccessDeniedMessage';
import FailureMessage from 'components/messages/FailureMessage';
import LoggedOutMessage from 'components/messages/LoggedOutMessage';
import NeedToSignInMessage from 'components/messages/NeedToSignInMessage';
import NoConnectionMessage from 'components/messages/NoConnectionMessage';
import UrlTypeInfoMessage from 'components/messages/UrlTypeInfoMessage';

import {
    closeAccessDeniedMessage,
    closeFailureMessage,
    closeLoggedOutMessage,
    closeNeedToSignInMessage,
    closeNoConnectionMessage,
    closeUrlTypeInfoMessage,
} from 'actions/uiActions';

import {
    accessDeniedMessageVisibilitySelector,
    failureMessageDetailsSelector,
    failureMessageHeaderSelector,
    failureMessageVisibilitySelector,
    loggedOutMessageVisibilitySelector,
    needToSignInMessageVisibilitySelector,
    noConnectionMessageVisibilitySelector,
    urlTypeInfoMessageVisibilitySelector,
} from 'selectors/uiSelectors';

import { userPlanTypeSelector } from 'selectors/userSelectors';

function MessageContainer(props) {
    return (
        <div>
            <AccessDeniedMessage visible={props.accessDeniedVisible} onClose={props.onCloseAccessDeniedMessage} />
            <NeedToSignInMessage visible={props.needToSignInVisible} onClose={props.onCloseNeedToSignInMessage} />
            <FailureMessage
                details={props.failureDetails}
                header={props.failureHeader}
                onClose={props.onCloseFailureMessage}
                visible={props.failureVisible}
            />
            <NoConnectionMessage onClose={props.onCloseNoConnectionMessage} visible={props.noConnectionVisible} />
            <LoggedOutMessage onClose={props.onCloseLoggedOutMessage} visible={props.loggedOutMessageVisible} />
            <UrlTypeInfoMessage onClose={props.onCloseUrlTypeInfoMessage} visible={props.urlTypeInfoMessageVisible} />
        </div>
    );
}

MessageContainer.propTypes = {
    accessDeniedVisible: bool.isRequired,
    failureDetails: string,
    failureHeader: string,
    failureVisible: bool.isRequired,
    loggedOutMessageVisible: bool.isRequired,
    needToSignInVisible: bool.isRequired,
    noConnectionVisible: bool.isRequired,
    onCloseAccessDeniedMessage: func.isRequired,
    onCloseFailureMessage: func.isRequired,
    onCloseLoggedOutMessage: func.isRequired,
    onCloseNeedToSignInMessage: func.isRequired,
    onCloseNoConnectionMessage: func.isRequired,
    onCloseUrlTypeInfoMessage: func.isRequired,
    urlTypeInfoMessageVisible: bool.isRequired,
};

const mapStateToProps = state => ({
    accessDeniedVisible: accessDeniedMessageVisibilitySelector(state),
    failureDetails: failureMessageDetailsSelector(state),
    failureHeader: failureMessageHeaderSelector(state),
    failureVisible: failureMessageVisibilitySelector(state),
    loggedOutMessageVisible: loggedOutMessageVisibilitySelector(state),
    needToSignInVisible: needToSignInMessageVisibilitySelector(state),
    noConnectionVisible: noConnectionMessageVisibilitySelector(state),
    userPlanType: userPlanTypeSelector(state),
    urlTypeInfoMessageVisible: urlTypeInfoMessageVisibilitySelector(state),
});

const mapDispatchToProps = dispatch => ({
    onCloseAccessDeniedMessage() {
        dispatch(closeAccessDeniedMessage());
    },
    onCloseFailureMessage() {
        dispatch(closeFailureMessage());
    },
    onCloseLoggedOutMessage() {
        dispatch(closeLoggedOutMessage());
    },
    onCloseNeedToSignInMessage() {
        dispatch(closeNeedToSignInMessage());
    },
    onCloseNoConnectionMessage() {
        dispatch(closeNoConnectionMessage());
    },
    onCloseUrlTypeInfoMessage() {
        dispatch(closeUrlTypeInfoMessage());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageContainer);

import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';

/* eslint-disable max-len */
// const FAKE_DATA = [{
//     citationFlow: 48,
//     trustFlow: 43,
//     url: 'https://kwfinder.com/',
//     title: 'KWFinder - Keyword research and analysis tool',
//     description: 'KWFinder is a keyword research and analysis tool bringing hundreds of long tail keywords with low SEO difficulty. Try KWFinder for free!',
//     facebookShares: 456,
//     refDomains: 1200,
//     linkCount: 3299
// }, {
//     citationFlow: 28,
//     trustFlow: 23,
//     url: 'https://kwfinder.com/features/',
//     title: 'KWFinder Features - Keyword research and analysis tool',
//     description: 'KWFinder is a keyword research and analysis tool bringing hundreds of long tail keywords with low SEO difficulty. Try KWFinder for free!',
//     facebookShares: 45,
//     refDomains: 120,
//     linkCount: 329
// }, {
//     citationFlow: 22,
//     trustFlow: 21,
//     url: 'https://kwfinder.com/features/long-tail-seo-keyword-search/',
//     title: 'KWFinder Features - Keyword research and analysis tool',
//     description: 'KWFinder is a keyword research and analysis tool bringing hundreds of long tail keywords with low SEO difficulty. Try KWFinder for free!',
//     facebookShares: 41,
//     refDomains: 124,
//     linkCount: 369
// }, {
//     citationFlow: 18,
//     trustFlow: 13,
//     url: 'https://kwfinder.com/features/filter-keywords/',
//     title: 'KWFinder Features - Keyword research and analysis tool',
//     description: 'KWFinder is a keyword research and analysis tool bringing hundreds of long tail keywords with low SEO difficulty. Try KWFinder for free!',
//     facebookShares: 25,
//     refDomains: 20,
//     linkCount: 29
// }, {
//     citationFlow: 15,
//     trustFlow: 12,
//     url: 'https://kwfinder.com/features/google-suggest/',
//     title: 'KWFinder Features - Keyword research and analysis tool',
//     description: 'KWFinder is a keyword research and analysis tool bringing hundreds of long tail keywords with low SEO difficulty. Try KWFinder for free!',
//     facebookShares: 15,
//     refDomains: 10,
//     linkCount: 9
// }];
/* eslint-enable max-len */

const initialState = {
    data: [],
    error: {
        status: null,
        text: null,
    },
    fetched: false,
    fetching: false,
};

const topContentOverviewDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_TOP_CONTENT_OVERVIEW_DATA_RECEIVED: {
            return update(state, {
                data: { $set: action.payload },
                error: { $set: initialState.error },
                fetching: { $set: false },
                fetched: { $set: true },
            });
        }
        case ActionTypes.DATA_TOP_CONTENT_OVERVIEW_DATA_FETCHING: {
            return update(state, {
                fetching: { $set: true },
            });
        }
        case ActionTypes.DATA_TOP_CONTENT_OVERVIEW_DATA_ERROR: {
            return update(state, {
                data: { $set: initialState.data },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
                fetching: { $set: false },
                fetched: { $set: true },
            });
        }
        case ActionTypes.UI_DASHBOARD_RESET: {
            return update(state, {
                fetched: { $set: false },
            });
        }
        default: {
            return state;
        }
    }
};

export default topContentOverviewDataReducer;

import React, { Component } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import HistoryPanelItem from 'components/panels/historyPanel/HistoryPanelItem';
import PanelItemPreloader from 'components/panels/PanelItemPreloader';
import emptyImg from 'images/empty.svg';

class HistoryPanel extends Component {
    constructor() {
        super();

        this.renderBody = this.renderBody.bind(this);
        this.renderEmptyMessage = this.renderEmptyMessage.bind(this);
        this.renderHistoryItems = this.renderHistoryItems.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (!this.props.visible && newProps.visible) {
            this.props.onOpen();
        }
    }

    shouldComponentUpdate(newProps, newState) {
        return shallowCompare(this, newProps, newState);
    }

    renderBody() {
        if (this.props.fetching) {
            return (
                <div style={{ cursor: 'progress' }}>
                    <PanelItemPreloader />
                    <PanelItemPreloader />
                    <PanelItemPreloader />
                    <PanelItemPreloader />
                    <PanelItemPreloader />
                </div>
            );
        } else if (this.props.data.length > 0) {
            return this.renderHistoryItems();
        } else {
            return this.renderEmptyMessage();
        }
    }

    renderEmptyMessage() {
        return (
            <div className="uk-height-1-1 cursor-default">
                <div className="uk-flex uk-flex-middle uk-flex-center uk-text-center uk-height-1-1">
                    <div>
                        <img className="mg-margin-b-15" src={emptyImg} alt="Oh no" width="100" />
                        <br />
                        <strong className="font-18">Your history is empty</strong>
                        <p className="mg-margin-b-10">
                            Start your backlink research now, your recent searches will be listed here.
                        </p>
                        <button className="mg-btn is-link is-small" onClick={this.props.onClose} type="button">
                            Close this panel
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    /* eslint-disable react/no-array-index-key */

    // Because of duplicates which we want to keep in list
    renderHistoryItems() {
        return this.props.data.map((item, index) => (
            <HistoryPanelItem key={`${item}-${index}`} onClick={this.props.onClick} url={item} />
        ));
    }

    /* eslint-enable react/no-array-index-key */

    render() {
        if (this.props.visible) {
            return (
                <div className="mg-panel mg-panel-history-box">
                    <div className="mg-panel-title mg-padding-30 mg-border-b">
                        <div className="uk-flex uk-flex-space-between">
                            <h1 className="uk-margin-remove font-24">
                                <FontAwesomeIcon icon="history" aria-hidden="true" className="mg-margin-r-10" />
                                <strong>Search history</strong>
                            </h1>
                            <button
                                className="mg-btn is-xsmall is-white-red mg-margin-r-15 uk-hidden-small"
                                onClick={this.props.onDeleteHistory}
                                disabled={this.props.fetching || this.props.data.length === 0}
                                type="button"
                            >
                                <FontAwesomeIcon icon={['far', 'trash-alt']} className="mg-margin-r-5" />
                                Clear history
                            </button>
                            <button
                                className="uk-position-top-right mg-close"
                                onClick={this.props.onClose}
                                type="button"
                            >
                                <FontAwesomeIcon icon="times" />
                            </button>
                        </div>
                    </div>
                    <div className="mg-panel-content">{this.renderBody()}</div>
                </div>
            );
        } else {
            return null;
        }
    }
}

HistoryPanel.propTypes = {
    data: arrayOf(string).isRequired,
    fetching: bool.isRequired,
    onClick: func.isRequired,
    onClose: func.isRequired,
    onOpen: func.isRequired,
    visible: bool.isRequired,
    onDeleteHistory: func.isRequired,
};

export default HistoryPanel;

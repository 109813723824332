export const TOP_REF_DOMAINS = {
    TOP_RANK: 'topRank',
    CITATION_FLOW: 'citationFlow',
    LINKS_TO: 'backlinkCount',
    TRUST_FLOW: 'trustFlow',
};

export const TOP_ANCHORS = {
    DO_FOLLOW_RATIO: 'doFollowRatio',
    REF_DOMAINS: 'refDomains',
    SHARE: 'sharePercent',
    TOTAL_LINKS: 'totalLinks',
};

export const TOP_CONTENT = {
    FB_SHARES: 'facebookShares',
    REF_DOMAINS: 'refDomains',
};

import { all, spawn } from 'redux-saga/effects';
import { initFlow, internalNavigationFlow } from 'sagas/flowSagas';
import { watchRouterRequests } from 'sagas/routerSagas';
import { watchUserRequests } from 'sagas/userSagas';
import { watchDataRequests } from 'sagas/dataSagas';
import { watchUIRequests } from 'sagas/uiSagas';

export default function* root() {
    yield all([
        spawn(initFlow),
        spawn(internalNavigationFlow),
        spawn(watchRouterRequests),
        spawn(watchUserRequests),
        spawn(watchDataRequests),
        spawn(watchUIRequests),
    ]);
}

export default {
    ALL: 'ALL',
    BRAND: 'BRAND',
    NAKED: 'NAKED',
    OTHER: 'OTHER',
};

export const ALL = {
    id: 0,
    label: 'All anchor types',
    value: 'ALL',
};

export const BRAND = {
    id: 1,
    label: 'Branded',
    value: 'BRAND',
};

export const NAKED = {
    id: 3,
    label: 'Naked',
    value: 'NAKED',
};

export const OTHER = {
    id: 5,
    label: 'Other',
    value: 'OTHER',
};

export const ANCHOR_TYPES_LIST = [ALL, BRAND, NAKED, OTHER];

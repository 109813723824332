import React, { lazy, Suspense } from 'react';
import { Route, IndexRoute } from 'react-router';

import FullAppPreloader from 'components/layout/FullAppPreloader';
import LayoutContainer from 'components/layout/LayoutContainer';
import AsyncLoaderErrorBoundaryContainer from 'components/AsyncLoaderErrorBoundaryContainer';

import RoutePaths from 'constants/RoutePaths';

const DashboardContainer = lazy(() => import('components/dashboard/DashboardContainer'));
const HomeContainer = lazy(() => import('components/home/HomeContainer'));
const NotFound = lazy(() => import('components/other/NotFound'));

// Initialize async route handlers

/* eslint-disable react/jsx-props-no-spreading */
function LoadDashboardContainer(props) {
    return (
        <AsyncLoaderErrorBoundaryContainer>
            <Suspense fallback={<FullAppPreloader />}>
                <DashboardContainer {...props} />
            </Suspense>
        </AsyncLoaderErrorBoundaryContainer>
    );
}

function LoadHomeContainer(props) {
    return (
        <AsyncLoaderErrorBoundaryContainer>
            <Suspense fallback={<FullAppPreloader />}>
                <HomeContainer {...props} />
            </Suspense>
        </AsyncLoaderErrorBoundaryContainer>
    );
}

function LoadNotFound(props) {
    return (
        <AsyncLoaderErrorBoundaryContainer>
            <Suspense fallback={<FullAppPreloader />}>
                <NotFound {...props} />
            </Suspense>
        </AsyncLoaderErrorBoundaryContainer>
    );
}
/* eslint-enable react/jsx-props-no-spreading */

export default (
    <Route path={RoutePaths.ROOT} component={LayoutContainer}>
        <IndexRoute component={LoadHomeContainer} />
        <Route path={RoutePaths.DASHBOARD} component={LoadDashboardContainer} />
        <Route path="*" component={LoadNotFound} />
    </Route>
);

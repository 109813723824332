// import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';

const initialState = {};

const modalsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_ALL_CLOSE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default modalsReducer;

import isNil from 'ramda/src/isNil';

const parseItem = rawItem => ({
    activeFrom: rawItem.active_from,
    activeTo: rawItem.active_to,
    btnText: rawItem.button_text,
    icon: rawItem.icon,
    messageText: rawItem.message,
    newTab: rawItem.new_tab,
    url: rawItem.url,
});

export const parse = rawData => (!isNil(rawData) ? parseItem(rawData) : null);

import React, { Component } from 'react';
import { bool, element, func, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import keymaster from 'keymaster';
import classnames from 'classnames';
import Alert from 'react-s-alert';

import Overlay from 'components/layout/Overlay';
import MessageContainer from 'components/messages/MessageContainer';
import ModalContainer from 'components/modals/ModalContainer';
import NavbarContainer from 'components/layout/navbar/NavbarContainer';
import PanelContainer from 'components/panels/PanelContainer';
import TopLevelMessageContainer from 'components/messages/topLevel/TopLevelMessageContainer';

import { closeAll } from 'actions/uiActions';

import {
    mobileOverlayVisibilitySelector,
    onlineStatusSelector,
    overlayVisibilitySelector,
} from 'selectors/uiSelectors';

import { newUserMessageVisibilitySelector } from 'selectors/userSelectors';

import { announcementMesageVisibilitySelector } from 'selectors/dataSelectors';

/**
 * Application layout component.
 */
class LayoutContainer extends Component {
    componentDidMount() {
        keymaster('esc', this.props.onCloseAll);
    }

    componentWillUnmount() {
        keymaster.unbind('esc', this.props.onCloseAll);
    }

    render() {
        const appClasses = classnames({
            'is-offline': this.props.onlineStatus === false,
            'siteprofiler-app': true,
            'is-messaged': this.props.topLevelMessageVisible,
        });

        return (
            <div className={appClasses}>
                <TopLevelMessageContainer />
                <NavbarContainer currentRoute={this.props.location.pathname} />
                <div>{this.props.children}</div>
                <ModalContainer currentRoute={this.props.location.pathname} />
                <MessageContainer />
                <PanelContainer />
                <Overlay mobile={false} onClick={this.props.onCloseAll} visibility={this.props.overlayVisibility} />
                <Overlay mobile onClick={this.props.onCloseAll} visibility={this.props.mobileOverlayVisibility} />
                <div className="uk-hidden-small">
                    <Alert effect="slide" offset={50} position="top-right" stack={{ limit: 3 }} timeout={3500} />
                </div>
            </div>
        );
    }
}

LayoutContainer.propTypes = {
    children: element.isRequired,
    location: shape({ pathname: string }).isRequired,
    mobileOverlayVisibility: bool.isRequired,
    onCloseAll: func.isRequired,
    onlineStatus: bool.isRequired,
    overlayVisibility: bool.isRequired,
    topLevelMessageVisible: bool.isRequired,
};

const mapStateToProps = state => ({
    mobileOverlayVisibility: mobileOverlayVisibilitySelector(state),
    onlineStatus: onlineStatusSelector(state),
    overlayVisibility: overlayVisibilitySelector(state),
    topLevelMessageVisible: newUserMessageVisibilitySelector(state) || announcementMesageVisibilitySelector(state),
});

const mapDispatchToProps = dispatch => ({
    onCloseAll() {
        dispatch(closeAll());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutContainer);

import { createSelector } from 'reselect';
import RouterService from 'services/RouterService';
import RoutePaths from 'constants/RoutePaths';
// ==============================================
// Shared UI selectors
// ==============================================

// Sorting stuff
export const dashboardTopAnchorsSortingSettingsSelector = state => state.ui.dashboard.sorting.topAnchors;
export const dashboardTopRefDomainsSortingSettingsSelector = state => state.ui.dashboard.sorting.topRefDomains;
export const dashboardTopContentSortingColumnSelector = state => state.ui.dashboard.sorting.topContent;
export const dashboardTopAnchorsSelectedAnchorTypeSelector = state =>
    state.ui.dashboard.filtering.topAnchors.selectedAnchorType;

// Messages
/* eslint-disable max-len */
export const topLevelAnnouncementMessageVisibilitySelector = state =>
    state.ui.messages.topLevel.announcement.visibility;
/* eslint-enable max-len */

// ==============================================
// Shared router selectors
// ==============================================
export const currentQuerySelector = state => state.routing.locationBeforeTransitions.query;
export const currentRouteSelector = state => state.routing.locationBeforeTransitions.pathname;
export const isReportRouteSelector = createSelector(currentRouteSelector, currentRoute =>
    RouterService.isSame(currentRoute, RoutePaths.REPORT),
);

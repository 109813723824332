import ActionTypes from 'constants/ActionTypes';
import update from 'immutability-helper';

export const ANNOUNCEMENT_EMPTY_MESSAGE = {
    activeFrom: 0,
    activeTo: 0,
    btnText: '',
    icon: '',
    messageText: '',
    newTab: false,
    url: '',
};

const initialState = {
    data: ANNOUNCEMENT_EMPTY_MESSAGE,
    error: {
        status: null,
        text: null,
    },
    fetching: false,
};

const announcementsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_ANNOUNCEMENTS_FETCHING: {
            return update(state, {
                fetching: { $set: true },
            });
        }
        case ActionTypes.DATA_ANNOUNCEMENTS_RECEIVED: {
            return update(state, {
                data: { $set: action.payload },
                error: {
                    status: { $set: initialState.error.status },
                    text: { $set: initialState.error.text },
                },
                fetching: { $set: false },
            });
        }
        case ActionTypes.DATA_ANNOUNCEMENTS_ERROR: {
            return update(state, {
                data: { $set: initialState.data },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
                fetching: { $set: false },
            });
        }
        default: {
            return state;
        }
    }
};

export default announcementsReducer;

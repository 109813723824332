// const PARSED_DATA_EXAMPLE = {
//     accessToken: '559ef2cd69702d0cd9020000'
//     createdAt: 1421070484000,
//     email: 'test@kwfinder.com',
//     id: '5abs6d5a8sd6asd5sa8ads8asd6asd5',
//     plan: 'free'
// };

import isNil from 'ramda/src/isNil';

const parseCreatedAt = rawCreatedAt => {
    if (rawCreatedAt) {
        return new Date(rawCreatedAt).getTime();
    } else {
        return null;
    }
};

export const parse = rawData => {
    if (isNil(rawData.user)) {
        return {
            accessToken: null,
            createdAt: null,
            email: null,
            id: null,
            plan: null,
        };
    } else {
        return {
            accessToken: rawData.user.api_key,
            createdAt: parseCreatedAt(rawData.user.created_at),
            email: rawData.user.email,
            id: rawData.user.id,
            plan: rawData.user.plan,
            isSubUser: !!rawData.organization,
        };
    }
};

import { select, spawn } from 'redux-saga/effects';
import GTMService from 'mangools-commons/lib/services/GTMService';
import { GTM_USER_DIMENSIONS } from 'mangools-commons/lib/constants/Analytics';

import { createdAtSelector, idSelector, loggedInSelector, userPlanSelector } from 'selectors/userSelectors';

export function* initAnalytics() {
    const userId = yield select(idSelector);
    const loggedIn = yield select(loggedInSelector);
    const userCreatedAt = yield select(createdAtSelector);
    const userPlan = yield select(userPlanSelector);

    yield spawn(GTMService.initialize, {
        ...process.env.GTM,
        dataLayer: {
            [GTM_USER_DIMENSIONS.user_login_status]: loggedIn,
            [GTM_USER_DIMENSIONS.user_created_at]: userCreatedAt,
            [GTM_USER_DIMENSIONS.user_plan]: userPlan,
            [GTM_USER_DIMENSIONS.user_id]: userId,
        }
    });
}

import { eventChannel } from 'redux-saga';
import { call, spawn, put, select, takeEvery } from 'redux-saga/effects';
import Alert from 'react-s-alert';
import ColorSchemes from 'mangools-commons/lib/constants/ColorSchemes';
import DOMService from 'mangools-commons/lib/services/DOMService';

import {
    receivedErrorNotificationAction,
    receivedInfoNotificationAction,
    requestedCloseAllNotificationsAction,
    resetDashboard,
    setOnlineStatus,
    setColorScheme,
} from 'actions/uiActions';

import { currentRouteSelector } from 'selectors/commonSelectors';
import { onlineStatusSelector, colorSchemeSelector } from 'selectors/uiSelectors';

import RouterService from 'services/RouterService';

import RoutePaths from 'constants/RoutePaths';
import ActionTypes from 'constants/ActionTypes';

// EXPORTS

export function* showErrorNotification(message, config = {}) {
    yield put(receivedErrorNotificationAction(message, config));
    yield call(Alert.error, message, config);
}

export function* showInfoNotification(message, config = {}) {
    yield put(receivedInfoNotificationAction(message, config));
    yield call(Alert.info, message, config);
}

export function* closeAllNotifications() {
    yield put(requestedCloseAllNotificationsAction());
    yield call(Alert.closeAll);
}

// WORKERS

function* updateOnlineStatus() {
    const currentStatus = yield select(onlineStatusSelector);
    const newStatus = window.navigator.onLine;
    yield put(setOnlineStatus({ onlineStatus: newStatus }));

    if (currentStatus === true && newStatus === false) {
        yield call(closeAllNotifications);
        // NOTE: Went from online to offline, show error notification
        yield call(showErrorNotification, 'You have lost your <strong>internet connection</strong>!', {
            html: true,
            timeout: 'none',
        });
    } else if (currentStatus === false && newStatus === true) {
        yield call(closeAllNotifications);
        // NOTE: Went from offline to online, show success notification
        yield call(showInfoNotification, 'You are now <strong>online</strong>!', {
            html: true,
        });
    }
}

// TODO: optimize by not resetting dashboard UI when previous route was not dashboard
function* resetDashboardUI() {
    const pathname = yield select(currentRouteSelector);
    const isDashboardRoute = yield call(RouterService.isSame, pathname, RoutePaths.DASHBOARD);

    if (!isDashboardRoute) {
        yield put(resetDashboard());
    }
}

function* updateColorSchemeFromChannel(channel) {
    const colorScheme = yield select(colorSchemeSelector);

    if (colorScheme === ColorSchemes.AUTO) {
        if (DOMService.isDarkMode(channel)) {
            DOMService.darkModeOn();
        } else {
            DOMService.darkModeOff();
        }

        // NOTE: this triggers force rerender in components to check for dark mode
        yield put(setColorScheme(ColorSchemes.LIGHT));
        yield put(setColorScheme(ColorSchemes.AUTO));
    }
}

function updateColorScheme({ payload }) {
    if (DOMService.isDarkMode(payload)) {
        DOMService.darkModeOn();
    } else {
        DOMService.darkModeOff();
    }
}

// CHANNELS

function onlineStatusChannels() {
    return eventChannel(emitter => {
        window.addEventListener('online', emitter);
        window.addEventListener('offline', emitter);

        return () => {
            window.removeEventListener('online', emitter);
            window.removeEventListener('offline', emitter);
        };
    });
}

function preferredColorSchemeChannel() {
    return eventChannel(emitter => {
        const mql = DOMService.registerMqlEventListener(colorScheme => emitter(colorScheme));
        return () => DOMService.removeMqlEventListener(mql);
    });
}

// WATCHERS

function* watchRouteChanged() {
    yield takeEvery(ActionTypes.ROUTER_LOCATION_CHANGE, resetDashboardUI);
}

function* watchOnlineStatusByEvents() {
    const channel = yield call(onlineStatusChannels);
    yield takeEvery(channel, updateOnlineStatus);
}

function* watchPreferredColorSchemeByEvents() {
    const channel = yield call(preferredColorSchemeChannel);
    yield takeEvery(channel, updateColorSchemeFromChannel);
}

function* watchPreferredColorSchemeChange() {
    yield takeEvery(ActionTypes.UI_MISC_COLOR_SCHEME_SET, updateColorScheme);
}

export function* watchUIRequests() {
    yield spawn(watchOnlineStatusByEvents);
    yield spawn(watchRouteChanged);
    yield spawn(watchPreferredColorSchemeByEvents);
    yield spawn(watchPreferredColorSchemeChange);
}

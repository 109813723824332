// String.xml like to have all UI texts in one place.
// Will help when I18n comes to mind, or just to ensure same jargon in whole app.

// TODO: migrate all string from APP here!
const Strings = {
    components: {
        common: {
            loading: 'Preparing your drink...',
        },
    },
    messages: {
        failure: {
            delete_favorite_list_error: 'There was an error, while deleting Your favorite list.',
            download_error: 'Sorry, download is not working in Your browser. Try Chrome or Firefox.',
            export_error: 'There was an error while exporting Your data.',
            fetch_announcements_error: 'There was an error while fetching announcements.',
            fetch_favorite_list_results_error: 'There was an error while fetching Your favorite links.',
            fetch_favorite_lists_error: 'There was an error while fetching Your favorites.',
            fetch_history_error: 'There was an error while fetching requests history data.',
            fetch_limits_error: 'There was an error while fetching Your account limit data.',
            fetch_data_error: 'There was an error while fetching data.',
            fetch_results_error: 'There was an error while fetching backlinks data.',
            footer_sorry: 'We are so sorry, try again later, please.',
            import_error: 'There was an error while importing Your keywords.',
            logout_error: 'There was an error while trying to log You out.',
            maintenance: 'Sorry, our services are undergoing a maintenance.',
            set_favorite_flag_error: 'There was an error while favoriting link.',
            too_many_requests_error: 'Sorry, you exceeded maximum allowed API requests per second.',
            delete_history: 'There was an error while deleting your history',
        },
    },
};

export default Strings;

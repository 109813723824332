import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ColorSchemes from 'mangools-commons/lib/constants/ColorSchemes';
import DOMService from 'mangools-commons/lib/services/DOMService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colorSchemeSelector } from 'selectors/uiSelectors';
import { setColorScheme } from 'actions/uiActions';

const AUTO_THEME = 'Auto';

const DarkModeToggle = () => {
    const dispatch = useDispatch();
    const colorScheme = useSelector(state => colorSchemeSelector(state));
    const hasMatchMedia = DOMService.hasMatchMedia();

    const switchMap = {
        [ColorSchemes.LIGHT]: {
            next: () => dispatch(setColorScheme(ColorSchemes.DARK)),
            icon: 'sun',
            title: 'Switch to dark theme',
            text: 'Light',
        },
        [ColorSchemes.DARK]: {
            next: hasMatchMedia
                ? () => dispatch(setColorScheme(ColorSchemes.AUTO))
                : () => dispatch(setColorScheme(ColorSchemes.LIGHT)),
            icon: 'moon',
            title: `Switch to ${hasMatchMedia ? 'auto' : 'light theme'}`,
            text: 'Dark',
        },
        [ColorSchemes.AUTO]: {
            next: () => dispatch(setColorScheme(ColorSchemes.LIGHT)),
            icon: ['fac', 'moon-auto'],
            title: 'Switch to light theme',
            text: AUTO_THEME,
        },
    };

    return (
        <button type="button" onClick={switchMap[colorScheme].next} title={switchMap[colorScheme].title}>
            <FontAwesomeIcon
                icon={switchMap[colorScheme].icon}
                aria-label="Theme toggle"
                className="uk-position-relative"
                style={switchMap[colorScheme].text === AUTO_THEME ? { left: '-2px' } : null}
            />
        </button>
    );
};

export default DarkModeToggle;

import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';

const initialState = {
    accessDenied: {
        visibility: false,
    },
    failure: {
        visibility: false,
        details: null,
        header: null,
    },
    loggedOut: {
        visibility: false,
    },
    needToSignIn: {
        visibility: false,
    },
    needToUpgradeAccount: {
        visibility: false,
    },
    noConnection: {
        visibility: false,
    },
    topLevel: {
        announcement: {
            visibility: true,
        },
    },
    urlTypeInfo: {
        visibility: false,
    },
};

const messagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_MESSAGES_FAILURE_SHOW: {
            return update(state, {
                failure: {
                    visibility: {
                        $set: true,
                    },
                    details: {
                        $set: action.payload.details,
                    },
                    header: {
                        $set: action.payload.header,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_FAILURE_CLOSE: {
            return update(state, {
                failure: {
                    visibility: {
                        $set: false,
                    },
                    details: {
                        $set: null,
                    },
                    header: {
                        $set: null,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_NEED_TO_SIGN_IN_SHOW: {
            return update(state, {
                needToSignIn: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_NEED_TO_SIGN_IN_CLOSE: {
            return update(state, {
                needToSignIn: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_NEED_TO_UPGRADE_ACCOUNT_SHOW: {
            return update(state, {
                needToUpgradeAccount: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_NEED_TO_UPGRADE_ACCOUNT_CLOSE: {
            return update(state, {
                needToUpgradeAccount: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_ACCESS_DENIED_SHOW: {
            return update(state, {
                accessDenied: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_ACCESS_DENIED_CLOSE: {
            return update(state, {
                accessDenied: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_TOP_LEVEL_ANNOUNCEMENT_CLOSE: {
            return update(state, {
                topLevel: {
                    announcement: {
                        visibility: {
                            $set: false,
                        },
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_NO_CONNECTION_SHOW: {
            return update(state, {
                noConnection: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_NO_CONNECTION_CLOSE: {
            return update(state, {
                noConnection: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_LOGGED_OUT_SHOW: {
            return update(state, {
                loggedOut: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_LOGGED_OUT_CLOSE: {
            return update(state, {
                loggedOut: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_URL_TYPE_INFO_SHOW: {
            return update(state, {
                urlTypeInfo: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_URL_TYPE_INFO_CLOSE: {
            return update(state, {
                urlTypeInfo: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_ALL_CLOSE: {
            return update(state, {
                accessDenied: {
                    visibility: { $set: false },
                },
                failure: {
                    visibility: { $set: false },
                    details: { $set: null },
                    header: { $set: null },
                },
                loggedOut: {
                    visibility: { $set: false },
                },
                needToUpgradeAccount: {
                    visibility: { $set: false },
                },
                noConnection: {
                    visibility: { $set: false },
                },
                urlTypeInfo: {
                    visibility: { $set: false },
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default messagesReducer;

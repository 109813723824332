import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';

// const FAKE_COMPETITORS = [{
//     topRank: 22,
//     citationFlow: 64,
//     domain: 'moz.com',
//     id: 'a',
//     trustFlow: 84,
//     url: 'https://moz.com'
// }, {
//     topRank: 21000,
//     citationFlow: 54,
//     domain: 'majestic.com',
//     id: 'b',
//     trustFlow: 74,
//     url: 'https://majestic.com'
// }, {
//     topRank: 26000,
//     citationFlow: 59,
//     domain: 'semrush.com',
//     id: 'c',
//     trustFlow: 71,
//     url: 'https://semrush.com'
// }, {
//     topRank: 96000,
//     citationFlow: 29,
//     domain: 'keywordtool.io',
//     id: 'd',
//     trustFlow: 31,
//     url: 'https://keywordtool.io'
// }, {
//     topRank: 86000,
//     citationFlow: 49,
//     domain: 'ahrefs.com',
//     id: 'e',
//     trustFlow: 61,
//     url: 'https://ahrefs.com'
// }, {
//     topRank: 93000,
//     citationFlow: 39,
//     domain: 'serpstat.com',
//     id: 'f',
//     trustFlow: 22,
//     url: 'https://serpstat.com'
// }, {
//     topRank: 126000,
//     citationFlow: 29,
//     domain: 'mondovo.com',
//     id: 'g',
//     trustFlow: 21,
//     url: 'https://mondovo.com'
// }];

const initialState = {
    data: [],
    error: {
        status: null,
        text: null,
    },
    fetched: false,
    fetching: false,
};

const competitorsOverviewDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_COMPETITORS_OVERVIEW_DATA_RECEIVED: {
            return update(state, {
                data: { $set: action.payload },
                error: { $set: initialState.error },
                fetching: { $set: false },
                fetched: { $set: true },
            });
        }
        case ActionTypes.DATA_COMPETITORS_OVERVIEW_DATA_FETCHING: {
            return update(state, {
                fetching: { $set: true },
            });
        }
        case ActionTypes.DATA_COMPETITORS_OVERVIEW_DATA_ERROR: {
            return update(state, {
                data: { $set: initialState.data },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
                fetching: { $set: false },
                fetched: { $set: true },
            });
        }
        case ActionTypes.UI_DASHBOARD_RESET: {
            return update(state, {
                fetched: { $set: false },
            });
        }
        default: {
            return state;
        }
    }
};

export default competitorsOverviewDataReducer;

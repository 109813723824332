import ActionTypes from 'constants/ActionTypes';
import update from 'immutability-helper';

const initialState = {
    data: [],
    error: {
        status: null,
        text: null,
    },
    fetching: false,
};

const resultsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_HISTORY_FETCHING: {
            return update(state, {
                fetching: { $set: true },
            });
        }
        case ActionTypes.DATA_HISTORY_RECEIVED: {
            return update(state, {
                data: { $set: action.payload },
                fetching: { $set: false },
                error: { $set: initialState.error },
            });
        }
        case ActionTypes.DATA_HISTORY_ERROR: {
            return update(state, {
                data: { $set: initialState.data },
                fetching: { $set: false },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        case ActionTypes.DATA_HISTORY_DELETE_RECEIVED: {
            return update(state, {
                data: { $set: initialState.data },
                fetching: { $set: false },
            });
        }
        case ActionTypes.DATA_HISTORY_DELETE_REQUESTED: {
            return update(state, {
                fetching: { $set: true },
            });
        }
        case ActionTypes.DATA_USER_LOGOUT_RECEIVED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default resultsReducer;

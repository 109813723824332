import React, { Component } from 'react';
import { bool, node } from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Rollbar from 'config/rollbar';
import Emoji from 'components/other/Emoji';

import { onlineStatusSelector } from 'selectors/uiSelectors';

const ERROR_TYPES = {
    CHUNK_LOAD: 'ChunkLoadError',
    OTHER: 'OTHER',
};

class AsyncLoaderErrorBoundaryContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            errorType: '',
        };

        this.handleReload = this.handleReload.bind(this);
        this.renderBody = this.renderBody.bind(this);
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {
            error: true,
            errorType: AsyncLoaderErrorBoundaryContainer.getErrorType(error),
        };
    }

    static getErrorType(error) {
        if (error.name === ERROR_TYPES.CHUNK_LOAD) {
            return ERROR_TYPES.CHUNK_LOAD;
        } else {
            return ERROR_TYPES.OTHER;
        }
    }

    componentDidCatch(error, _errorInfo) {
        if (this.props.online === true) {
            Rollbar.error(error);
        }
    }

    handleReload() {
        // NOTE: Reloading the page and forcing not using cache
        return location.reload(true); // eslint-disable-line no-restricted-globals
    }

    renderBody() {
        if (this.props.online === true) {
            if (this.state.errorType === ERROR_TYPES.CHUNK_LOAD) {
                return (
                    <div className="mg-padding-30">
                        <h1 className="font-36 mg-margin-b-30">
                            <FontAwesomeIcon icon="info-circle" className="mg-margin-r-10 uk-hidden-small" />
                            Application outdated
                        </h1>
                        <p className="uk-margin-top font-24 color-grey mg-margin-b-30">
                            Looks like there is a <strong>new application version available</strong>.<br />
                            To get the newest version, reload this page, please.
                        </p>
                        <button className="mg-btn is-blue" onClick={this.handleReload} type="button">
                            <FontAwesomeIcon icon="sync" className="mg-margin-r-10" />
                            Reload page
                        </button>
                    </div>
                );
            } else {
                return (
                    <div className="mg-padding-30">
                        <h1 className="font-36 mg-margin-b-30">
                            Sorry, something went wrong <Emoji symbol="😢" label="crying-face" />
                        </h1>
                        <p className="uk-margin-top font-24 color-grey mg-margin-b-30">
                            Looks like there was an <strong>error in the application</strong>.<br />
                            If you see this repeatedly, contact our support please.
                        </p>
                    </div>
                );
            }
        } else {
            return (
                <div className="mg-padding-30">
                    <h1 className="font-36 mg-margin-b-30">
                        <FontAwesomeIcon icon="plug" className="mg-margin-r-10 uk-hidden-small" />
                        Connection&nbsp;problem
                    </h1>
                    <p className="uk-margin-top font-24 color-grey mg-margin-b-30">
                        Looks like you have <strong>lost connection</strong> to the server or the
                        <br />
                        <strong>request was blocked by a 3rd party application</strong>
                        <br />
                        (e.g. browser extension like adBlock).
                    </p>
                    <button className="mg-btn is-blue" onClick={this.handleReload} type="button">
                        <FontAwesomeIcon icon="sync" className="mg-margin-r-10" />
                        Reload page
                    </button>
                </div>
            );
        }
    }

    render() {
        if (this.state.error === true) {
            return (
                <div className="kwfinder-main">
                    <div className="uk-container uk-container-center">
                        <div className="uk-flex uk-flex-middle uk-flex-center uk-position-cover">
                            {this.renderBody()}
                        </div>
                    </div>
                </div>
            );
        } else {
            return this.props.children;
        }
    }
}
const mapStateToProps = state => ({
    online: onlineStatusSelector(state),
});

AsyncLoaderErrorBoundaryContainer.propTypes = {
    children: node.isRequired,
    online: bool.isRequired,
};

export default connect(mapStateToProps)(AsyncLoaderErrorBoundaryContainer);

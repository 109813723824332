import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';

const initialState = {
    data: {
        history: [],
        refDomains: 0,
        refIPs: 0,
        refSubnets: 0,
        topAnchors: [],
        topRefDomains: [],
        totalDeletedLinks: 0,
        totalFrameLinks: 0,
        totalImageLinks: 0,
        totalLinks: 0,
        totalNoFollowLinks: 0,
        totalRedirectLinks: 0,
        totalTextLinks: 0,
    },
    error: {
        status: null,
        text: null,
    },
    fetched: false,
    fetching: false,
};

const backlinkProfileOverviewDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_BACKLINK_PROFILE_OVERVIEW_DATA_FETCHING: {
            return update(state, {
                fetching: { $set: true },
            });
        }
        case ActionTypes.DATA_BACKLINK_PROFILE_OVERVIEW_DATA_RECEIVED: {
            return update(state, {
                data: {
                    history: { $set: action.payload.history },
                    refDomains: { $set: action.payload.refDomains },
                    refIPs: { $set: action.payload.refIPs },
                    refSubnets: { $set: action.payload.refSubnets },
                    topAnchors: { $set: action.payload.topAnchors },
                    topRefDomains: { $set: action.payload.topRefDomains },
                    totalDeletedLinks: { $set: action.payload.totalDeletedLinks },
                    totalFrameLinks: { $set: action.payload.totalFrameLinks },
                    totalImageLinks: { $set: action.payload.totalImageLinks },
                    totalLinks: { $set: action.payload.totalLinks },
                    totalNoFollowLinks: { $set: action.payload.totalNoFollowLinks },
                    totalRedirectLinks: { $set: action.payload.totalRedirectLinks },
                    totalTextLinks: { $set: action.payload.totalTextLinks },
                },
                error: { $set: initialState.error },
                fetching: { $set: false },
                fetched: { $set: true },
            });
        }
        case ActionTypes.DATA_BACKLINK_PROFILE_OVERVIEW_DATA_ERROR: {
            return update(state, {
                data: { $set: initialState.data },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
                fetching: { $set: false },
                fetched: { $set: true },
            });
        }
        case ActionTypes.UI_DASHBOARD_RESET: {
            return update(state, {
                fetched: { $set: false },
            });
        }
        default: {
            return state;
        }
    }
};

export default backlinkProfileOverviewDataReducer;

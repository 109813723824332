import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';
import UrlTypes from 'constants/UrlTypes';

const initialState = {
    data: {
        topRank: {
            current: 0,
            change: 0,
            history: [],
        },
        citationFlow: 0,
        domainAuthority: 0,
        facebook: {
            current: 0,
            history: [],
            title: '',
        },
        pageAuthority: 0,
        refIPs: {
            current: 0,
            history: [],
        },
        trustFlow: 0,
    },
    error: {
        status: null,
        text: null,
        type: null,
    },
    fetched: false,
    fetching: false,
    meta: {
        dataAvailable: true,
        urlType: UrlTypes.ROOT_DOMAIN,
    },
};

const mainOverviewDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_MAIN_OVERVIEW_DATA_RECEIVED: {
            return update(state, {
                data: {
                    topRank: {
                        change: { $set: action.payload.topRank.change },
                        current: { $set: action.payload.topRank.current },
                        history: { $set: action.payload.topRank.history },
                    },
                    citationFlow: { $set: action.payload.citationFlow },
                    domainAuthority: { $set: action.payload.domainAuthority },
                    facebook: {
                        current: { $set: action.payload.facebook.current },
                        history: { $set: action.payload.facebook.history },
                        title: { $set: action.payload.facebook.title },
                    },
                    pageAuthority: { $set: action.payload.pageAuthority },
                    refIPs: {
                        current: { $set: action.payload.refIPs.current },
                        history: { $set: action.payload.refIPs.history },
                    },
                    trustFlow: { $set: action.payload.trustFlow },
                },
                error: { $set: initialState.error },
                fetched: { $set: true },
                fetching: { $set: false },
                meta: {
                    dataAvailable: { $set: action.payload.dataAvailable },
                    urlType: { $set: action.payload.meta.urlType },
                },
            });
        }
        case ActionTypes.DATA_MAIN_OVERVIEW_DATA_FETCHING: {
            return update(state, {
                fetching: { $set: true },
            });
        }
        case ActionTypes.DATA_MAIN_OVERVIEW_DATA_ERROR: {
            return update(state, {
                data: { $set: initialState.data },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                    type: { $set: action.payload.type },
                },
                fetched: { $set: true },
                fetching: { $set: false },
            });
        }
        case ActionTypes.UI_DASHBOARD_RESET: {
            return update(state, {
                fetched: { $set: false },
            });
        }
        default: {
            return state;
        }
    }
};

export default mainOverviewDataReducer;

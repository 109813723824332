export const analyticsActions = {
    SHOW: 'show',
    CLOSE: 'close',
    CONFIRM: 'confirm',
    LOGIN: 'login',
    REGISTER: 'register',
};

export const analyticsEvents = {
    OUT_OF_LIMITS_MESSAGE: 'out_of_limits_message',
    LOGIN_MESSAGE: 'login_message',
    THEME_TOGGLE: 'theme_toggle',
};

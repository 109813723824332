import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

// import defaultsReducer from 'reducers/ui/defaultsReducer';
import announcementsReducer from 'reducers/data/announcementsReducer';
// import audienceOverviewDataReducer from 'reducers/data/sections/audienceOverviewDataReducer';
import backlinkProfileOverviewDataReducer from 'reducers/data/sections/backlinkProfileOverviewDataReducer';
import competitorsOverviewDataReducer from 'reducers/data/sections/competitorsOverviewDataReducer';
import dashboardReducer from 'reducers/ui/dashboardReducer';
import dropdownsReducer from 'reducers/ui/dropdownsReducer';
import historyReducer from 'reducers/data/historyReducer';
import mainOverviewDataReducer from 'reducers/data/sections/mainOverviewDataReducer';
import messagesReducer from 'reducers/ui/messagesReducer';
import miscReducer from 'reducers/ui/miscReducer';
import modalsReducer from 'reducers/ui/modalsReducer';
import panelsReducer from 'reducers/ui/panelsReducer';
import paramsReducer from 'reducers/paramsReducer';
import topContentOverviewDataReducer from 'reducers/data/sections/topContentOverviewDataReducer';
// import trafficOverviewDataReducer from 'reducers/data/sections/trafficOverviewDataReducer';
import userReducer from 'reducers/userReducer';

const rootReducer = combineReducers({
    data: combineReducers({
        announcements: announcementsReducer,
        history: historyReducer,
        sections: combineReducers({
            // audienceOverview: audienceOverviewDataReducer,
            backlinkProfileOverview: backlinkProfileOverviewDataReducer,
            competitorsOverview: competitorsOverviewDataReducer,
            mainOverview: mainOverviewDataReducer,
            topContentOverview: topContentOverviewDataReducer,
            // trafficOverview: trafficOverviewDataReducer,
        }),
    }),
    params: paramsReducer,
    routing: routerReducer,
    ui: combineReducers({
        // defaults: defaultsReducer,
        dashboard: dashboardReducer,
        dropdowns: dropdownsReducer,
        messages: messagesReducer,
        misc: miscReducer,
        modals: modalsReducer,
        panels: panelsReducer,
    }),
    user: userReducer,
});

export default rootReducer;

import React from 'react';
import { connect } from 'react-redux';

function ModalContainer(_props) {
    return <div className="modals" />;
}

const mapStateToProps = _state => ({});
const mapDispatchToProps = _dispatch => ({});

ModalContainer.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);

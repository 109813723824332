import React, { Component } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import { connect } from 'react-redux';

import AppPanel from 'components/panels/AppPanel';
import HistoryPanel from 'components/panels/HistoryPanel';
import RoutePaths from 'constants/RoutePaths';

import { closeAppPanel, closeHistoryPanel } from 'actions/uiActions';

import { requestedHistoryAction, requestedDeleteHistoryAction } from 'actions/dataActions';

import { appPanelVisibilitySelector, historyPanelVisibilitySelector } from 'selectors/uiSelectors';

import { fetchingHistoryDataSelector, historyDataSelector } from 'selectors/dataSelectors';

import { requestedNavigationAction } from 'actions/routerActions';

class PanelContainer extends Component {
    constructor() {
        super();
        this.handleHistoryClick = this.handleHistoryClick.bind(this);
    }

    handleHistoryClick({ url }) {
        this.props.onCloseHistoryPanel();
        this.props.requestNavigation(RoutePaths.DASHBOARD, {
            url,
        });
    }

    render() {
        return (
            <div>
                <AppPanel onClose={this.props.onCloseAppPanel} visible={this.props.appPanelVisible} />
                <HistoryPanel
                    data={this.props.historyData}
                    fetching={this.props.fetchingHistoryData}
                    onClick={this.handleHistoryClick}
                    onClose={this.props.onCloseHistoryPanel}
                    onOpen={this.props.requestHistoryFetch}
                    onDeleteHistory={this.props.requestHistoryDelete}
                    visible={this.props.historyPanelVisible}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    appPanelVisible: appPanelVisibilitySelector(state),
    fetchingHistoryData: fetchingHistoryDataSelector(state),
    historyData: historyDataSelector(state),
    historyPanelVisible: historyPanelVisibilitySelector(state),
});

const mapDispatchToProps = dispatch => ({
    onCloseAppPanel() {
        dispatch(closeAppPanel());
    },
    onCloseHistoryPanel() {
        dispatch(closeHistoryPanel());
    },
    requestNavigation(route, params) {
        dispatch(requestedNavigationAction(route, params));
    },
    requestHistoryFetch() {
        dispatch(requestedHistoryAction());
    },
    requestHistoryDelete() {
        dispatch(requestedDeleteHistoryAction());
    },
});

PanelContainer.propTypes = {
    appPanelVisible: bool.isRequired,
    fetchingHistoryData: bool.isRequired,
    historyData: arrayOf(string).isRequired,
    historyPanelVisible: bool.isRequired,
    onCloseAppPanel: func.isRequired,
    onCloseHistoryPanel: func.isRequired,
    requestHistoryFetch: func.isRequired,
    requestNavigation: func.isRequired,
    requestHistoryDelete: func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelContainer);

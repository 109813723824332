const UserPlanTypes = {
    AGENCY: 'AGENCY',
    BASIC: 'BASIC',
    CUSTOM: 'CUSTOM',
    FREE: 'FREE',
    NO_PLAN: 'NO_PLAN',
    PREMIUM: 'PREMIUM',
    TRIAL: 'TRIAL',
    ENTRY: 'ENTRY',
};

export const haveAnyPlan = plan =>
    plan === UserPlanTypes.FREE ||
    plan === UserPlanTypes.TRIAL ||
    plan === UserPlanTypes.ENTRY ||
    plan === UserPlanTypes.BASIC ||
    plan === UserPlanTypes.PREMIUM ||
    plan === UserPlanTypes.AGENCY ||
    plan === UserPlanTypes.CUSTOM;

export default UserPlanTypes;

import CSV from 'comma-separated-values';
import CSVExportService from 'mangools-commons/lib/services/CSVExportService';

import { BRAND, NAKED, OTHER } from 'constants/AnchorTypes';
import ExportTypes from 'constants/ExportTypes';

class ExportService {
    static export({ data, includeMetrics, type }) {
        switch (type) {
            case ExportTypes.TOP_ANCHORS: {
                return ExportService.exportTopAnchors({ data, includeMetrics });
            }
            case ExportTypes.TOP_REF_DOMAINS: {
                return ExportService.exportTopRefDomains({ data, includeMetrics });
            }
            default: {
                return null;
            }
        }
    }

    static exportTopAnchors({ data, includeMetrics }) {
        let columns = [];
        let result = [];

        if (includeMetrics === false) {
            columns = ['Anchor text'];
            result = data.map(item => [item.anchor]);
        } else {
            columns = ['Anchor text', 'Type', 'Ref. domains', 'Total', 'Active', 'Do follow', 'Share'];

            result = data.map(item => [
                item.anchor,
                ExportService.formatAnchorTypeName(item.type),
                item.refDomains,
                item.totalLinks,
                item.activeRatio,
                item.doFollowRatio,
                item.sharePercent,
            ]);
        }

        return CSV.encode(result, {
            header: columns,
        });
    }

    static exportTopRefDomains({ data, includeMetrics }) {
        let columns = [];
        let result = [];

        if (includeMetrics === false) {
            columns = ['Ref. domain'];
            result = data.map(item => [item.domain]);
        } else {
            columns = ['Ref. domain', 'Citation Flow', 'Trust Flow', 'Links to', 'Top Rank'];

            result = data.map(item => [
                item.domain,
                item.citationFlow,
                item.trustFlow,
                item.backlinkCount,
                CSVExportService.formatEmptyValue(item.topRank),
            ]);
        }

        return CSV.encode(result, {
            header: columns,
        });
    }

    static formatAnchorTypeName(type) {
        switch (type) {
            case BRAND.value: {
                return 'Branded';
            }
            case NAKED.value: {
                return 'Naked';
            }
            case OTHER.value:
            default: {
                return 'Other';
            }
        }
    }
}

export default ExportService;

import { createSelector } from 'reselect';
import any from 'ramda/src/any';
import ascend from 'ramda/src/ascend';
import descend from 'ramda/src/descend';
import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import prop from 'ramda/src/prop';
import reverse from 'ramda/src/reverse';
import slice from 'ramda/src/slice';
import sortWith from 'ramda/src/sortWith';
import update from 'immutability-helper';

import Defaults from 'mangools-commons/lib/constants/Defaults';

import {
    dashboardTopAnchorsSelectedAnchorTypeSelector,
    dashboardTopAnchorsSortingSettingsSelector,
    dashboardTopContentSortingColumnSelector,
    dashboardTopRefDomainsSortingSettingsSelector,
    topLevelAnnouncementMessageVisibilitySelector,
} from 'selectors/commonSelectors';

import { limitsFetchedSelector, newUserMessageVisibilitySelector } from 'selectors/userSelectors';

import { ANNOUNCEMENT_EMPTY_MESSAGE } from 'reducers/data/announcementsReducer';

import MathService from 'services/MathService';
import AnchorTypes, { BRAND, NAKED, OTHER } from 'constants/AnchorTypes';
import LinkTypes from 'constants/LinkTypes';
import RefDomainsTopRankRanges from 'constants/RefDomainsTopRankRanges';
import SortingTypes from 'constants/SortingTypes';
import { NO_ANCHOR } from 'constants/Other';
import moment from 'moment';

// ================================================================================================
// Section data
// ================================================================================================

// ----------------------------------------------------------------------------
// Main Overview
// ----------------------------------------------------------------------------

export const rawMainOverviewDataSelector = state => state.data.sections.mainOverview.data;
export const mainOverviewErrorSelector = state => state.data.sections.mainOverview.error;
export const mainOverviewDataFetchedSelector = state => state.data.sections.mainOverview.fetched;
export const mainOverviewDataFetchingSelector = state => state.data.sections.mainOverview.fetching;
export const mainOverviewMetaUrlTypeSelector = state => state.data.sections.mainOverview.meta.urlType;
export const mainOverviewMetaDataAvailableSelector = state => state.data.sections.mainOverview.meta.dataAvailable;

export function reduceToWeeks(history, weekCount = 52) {
    if (!isNil(history) && !isEmpty(history)) {
        const reversedHistory = reverse(history);
        const startDate = moment().startOf('week').add(1, 'day');

        const mappedHistory = reversedHistory.map(item => {
            if (startDate.diff(item.date, 'days') % 7 === 0) {
                if (!isNil(item.value)) {
                    return item;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        });

        const filteredHistory = mappedHistory.filter(item => item !== null);
        if (weekCount > 0) {
            const limitedHistory = slice(0, weekCount, filteredHistory);
            return reverse(limitedHistory);
        }

        return reverse(filteredHistory);
    } else {
        return history;
    }
}

// NOTE:
// Will copy previous item value if the current
// one is lower.
//
// CATION: As is running on the same array, the change
// is not propagated, so it only works for one time cases,
// which is fine for us now.
//
// If needed to behave resursively, it should update the array
// in-place, so the next increment sees different value.
function ensureRisingValues(history) {
    return history.map((item, index) => {
        if (index > 0) {
            const prevItem = history[index - 1];

            if (item.value < prevItem.value) {
                return update(item, {
                    value: { $set: prevItem.value },
                });
            } else {
                return item;
            }
        } else {
            // This is first item, just return it
            return item;
        }
    });
}

/* eslint-disable max-len */
export const mainOverviewDataSelector = createSelector([rawMainOverviewDataSelector], rawData => {
    const { topRank, citationFlow, domainAuthority, facebook, pageAuthority, refIPs, trustFlow } = rawData;

    const topRankHistoryByWeeks = reduceToWeeks(topRank.history);
    const facebookHistoryByWeeks = ensureRisingValues(reduceToWeeks(facebook.history));
    const refIPsHistoryByWeeks = reduceToWeeks(refIPs.history);

    const topRankHistory = any(item => item.value > 0)(topRankHistoryByWeeks) ? topRankHistoryByWeeks : [];
    const facebookHistory = any(item => item.value > 0)(facebookHistoryByWeeks) ? facebookHistoryByWeeks : [];
    const refIPsHistory = any(item => item.value > 0)(refIPsHistoryByWeeks) ? refIPsHistoryByWeeks : [];
    
    const topRankFullHistory = any(item => item.value > 0)(topRank.history) ? topRank.history : [];
    const facebookFullHistory = any(item => item.value > 0)(facebook.history)
        ? ensureRisingValues(facebook.history)
        : [];
    const refIPsFullHistory = any(item => item.value > 0)(refIPs.history) ? refIPs.history : [];

    const firstTopRankHistoryItem = !isEmpty(topRankHistory) ? topRankHistory[0].value : Defaults.NOT_AVAILABLE;
    const lastTopRankHistoryItem = !isEmpty(topRankHistory)
        ? topRankHistory[topRankHistory.length - 1].value
        : Defaults.NOT_AVAILABLE;

    const firstFbHistoryItem = !isEmpty(facebookHistory) ? facebookHistory[0].value : Defaults.NOT_AVAILABLE;
    const lastFbHistoryItem = !isEmpty(facebookHistory)
        ? facebookHistory[facebookHistory.length - 1].value
        : Defaults.NOT_AVAILABLE;

    const firstRefIPsHistoryItem = !isEmpty(refIPsHistory) ? refIPsHistory[0].value : Defaults.NOT_AVAILABLE;
    const lastRefIPsHistoryItem = !isEmpty(refIPsHistory)
        ? refIPsHistory[refIPsHistory.length - 1].value
        : Defaults.NOT_AVAILABLE;

    return {
        topRank: {
            change: MathService.diffPercent({
                reference: firstTopRankHistoryItem,
                value: lastTopRankHistoryItem,
            }),
            current: topRank.current,
            history: topRankHistory,
            fullHistory: topRankFullHistory,
        },
        citationFlow,
        domainAuthority,
        facebook: {
            change: MathService.diffPercent({
                reference: firstFbHistoryItem,
                value: lastFbHistoryItem,
            }),
            current: facebook.current,
            history: facebookHistory,
            fullHistory: facebookFullHistory,
            title: facebook.title,
        },
        pageAuthority,
        refIPs: {
            change: MathService.diffPercent({
                reference: firstRefIPsHistoryItem,
                value: lastRefIPsHistoryItem,
            }),
            current: refIPs.current,
            history: refIPsHistory,
            fullHistory: refIPsFullHistory,
        },
        trustFlow,
    };
});
/* eslint-enable max-len */

// ----------------------------------------------------------------------------
// Traffic Overview
// ----------------------------------------------------------------------------

const rawTrafficOverviewDataSelector = state => state.data.sections.trafficOverview.data;
// const trafficOverviewDataGeoDistributionSelector = state => state.data.sections.trafficOverview.data.geoDistribution;

export const trafficOverviewDataFetchingSelector = state => state.data.sections.trafficOverview.fetching;
export const trafficOverviewDataFetchedSelector = state => state.data.sections.trafficOverview.fetched;

function calculateDailyEstimatedUsers({ visits, pagesPerVisit }) {
    if (visits > 0 && pagesPerVisit > 0) {
        return Math.floor(visits / pagesPerVisit / 30);
    } else {
        return Defaults.NOT_AVAILABLE;
    }
}

function calculateDailyEstimatedUsersChange({ lastMonthDailyEstimatedUsers, prevMonthDailyEstimatedUsers }) {
    if (
        lastMonthDailyEstimatedUsers !== Defaults.NOT_AVAILABLE &&
        prevMonthDailyEstimatedUsers !== Defaults.NOT_AVAILABLE
    ) {
        return MathService.diffPercent({
            reference: prevMonthDailyEstimatedUsers,
            value: lastMonthDailyEstimatedUsers,
        });
    } else {
        return Defaults.NOT_AVAILABLE;
    }
}

export const trafficOverviewDataSelector = createSelector([rawTrafficOverviewDataSelector], rawData => {
    const pagesPerVisit = rawData.engagement.pagesPerVisit.value;
    const pagesPerVisitChangePercent = rawData.engagement.pagesPerVisit.changePercent;

    const history = rawData.traffic.history; // eslint-disable-line prefer-destructuring
    const lastHistoryItem = history.length > 0 ? history[history.length - 1] : Defaults.NOT_AVAILABLE;
    const prevHistoryItem = history.length > 1 ? history[history.length - 2] : Defaults.NOT_AVAILABLE;

    const lastMonthDailyEstimatedUsers = calculateDailyEstimatedUsers({
        visits: lastHistoryItem !== Defaults.NOT_AVAILABLE ? lastHistoryItem.value : Defaults.NOT_AVAILABLE,
        pagesPerVisit,
    });

    const prevMonthDailyEstimatedUsers = calculateDailyEstimatedUsers({
        visits: prevHistoryItem !== Defaults.NOT_AVAILABLE ? prevHistoryItem.value : Defaults.NOT_AVAILABLE,
        pagesPerVisit: pagesPerVisit / (1 + pagesPerVisitChangePercent / 100),
    });

    const lastMonthDailyEstimatedUsersChange = calculateDailyEstimatedUsersChange({
        lastMonthDailyEstimatedUsers,
        prevMonthDailyEstimatedUsers,
    });

    return {
        engagement: {
            avgTimeOnSite: {
                value: rawData.engagement.avgTimeOnSite.value,
                changePercent: rawData.engagement.avgTimeOnSite.changePercent,
            },
            bounceRate: {
                value: rawData.engagement.bounceRate.value,
                changePercent: rawData.engagement.bounceRate.changePercent,
            },
            lastMonthDailyEstimatedUsers: {
                value: lastMonthDailyEstimatedUsers,
                changePercent: lastMonthDailyEstimatedUsersChange,
            },
            pagesPerVisit: {
                value: pagesPerVisit,
                changePercent: pagesPerVisitChangePercent,
            },
        },
        geoDistribution: rawData.geoDistribution,
        keywords: {
            organic: sortWith([descend(prop('searchVolume'))])(rawData.keywords.organic),
            paid: sortWith([descend(prop('searchVolume'))])(rawData.keywords.paid),
        },
        traffic: {
            history,
            totalVisits: rawData.traffic.totalVisits,
            totalVisitsChangePercent: rawData.traffic.totalVisitsChangePercent,
        },
        sourceDistribution: rawData.sourceDistribution,
    };
});

// ----------------------------------------------------------------------------
// Competitors Overview
// ----------------------------------------------------------------------------

export const competitorsOverviewDataSelector = state => state.data.sections.competitorsOverview.data;
export const competitorsOverviewDataFetchingSelector = state => state.data.sections.competitorsOverview.fetching;
export const competitorsOverviewDataFetchedSelector = state => state.data.sections.competitorsOverview.fetched;

// ----------------------------------------------------------------------------
// Top Content Overview
// ----------------------------------------------------------------------------

export const rawTopContentOverviewDataSelector = state => state.data.sections.topContentOverview.data;
export const topContentOverviewDataFetchingSelector = state => state.data.sections.topContentOverview.fetching;
export const topContentOverviewDataFetchedSelector = state => state.data.sections.topContentOverview.fetched;

export const topContentOverviewDataSelector = createSelector(
    [rawTopContentOverviewDataSelector, dashboardTopContentSortingColumnSelector],
    (data, sortingColumn) => sortWith([descend(prop(sortingColumn))])(data),
);

// ----------------------------------------------------------------------------
// Backlink Profile Overview
// ----------------------------------------------------------------------------

const rawBacklinkProfileOverviewDataSelector = state => state.data.sections.backlinkProfileOverview.data;
/* eslint-disable max-len */
export const backlinkProfileOverviewDataFetchingSelector = state =>
    state.data.sections.backlinkProfileOverview.fetching;
/* eslint-enable max-len */
export const backlinkProfileOverviewDataFetchedSelector = state => state.data.sections.backlinkProfileOverview.fetched;

/* eslint-disable no-nested-ternary */
function getRatio({ value, total, inverted = false }) {
    const ratio =
        value !== Defaults.NOT_AVAILABLE && total !== Defaults.NOT_AVAILABLE
            ? MathService.getPercent({ value, total })
            : Defaults.NOT_AVAILABLE;

    return ratio !== Defaults.NOT_AVAILABLE
        ? inverted === true
            ? parseFloat((100 - ratio).toFixed(2), 10)
            : parseFloat(ratio.toFixed(2), 10)
        : Defaults.NOT_AVAILABLE;
}

/* eslint-enable no-nested-ternary */

function generateTotalInHistory(data, total) {
    if (!isEmpty(data) && !isNil(total)) {
        const reversedData = reverse(data);
        let currentTotal = total;

        const result = reversedData.map((item, index) => {
            if (index > 0) {
                currentTotal = currentTotal - item.new + item.deleted;
            }

            return {
                date: item.date,
                new: item.new,
                deleted: item.deleted,
                total: currentTotal,
            };
        });

        return reverse(result);
    } else {
        return [];
    }
}

// NOTE: Trying to make sense of majestic history data...
// function generateTotalInHistory(data) {
//     if (!isEmpty(data)) {
//         let currentTotal = 0;

//         return data.map((item, index) => {
//             if (index > 0) {
//                 currentTotal = (currentTotal + item.new) - item.deleted;
//             }

//             return {
//                 date: item.date,
//                 new: item.new,
//                 deleted: item.deleted,
//                 total: currentTotal
//             };
//         });
//     } else {
//         return [];
//     }
// }

function generateTopAnchors({ data, totalLinks, selectedAnchorType, sortingSettings }) {
    let result = [];

    result = data.map(item => ({
        activeRatio: getRatio({ value: item.deletedLinks, total: item.totalLinks, inverted: true }),
        anchor: item.anchor === '' ? NO_ANCHOR : item.anchor,
        doFollowRatio: getRatio({ value: item.noFollowLinks, total: item.totalLinks, inverted: true }),
        refDomains: item.refDomains,
        sharePercent: getRatio({ value: item.totalLinks, total: totalLinks }),
        totalLinks: item.totalLinks,
        type: item.type,
    }));

    if (selectedAnchorType.value !== AnchorTypes.ALL) {
        result = result.filter(item => item.type === selectedAnchorType.value);
    }

    if (sortingSettings.type === SortingTypes.ASC) {
        return sortWith([ascend(prop(sortingSettings.column))])(result);
    } else {
        return sortWith([descend(prop(sortingSettings.column))])(result);
    }
}

function generateTopRefDomains({ data, sortingSettings }) {
    if (sortingSettings.type === SortingTypes.ASC) {
        return sortWith([ascend(prop(sortingSettings.column))])(data);
    } else {
        return sortWith([descend(prop(sortingSettings.column))])(data);
    }
}

/* eslint-disable no-param-reassign */
function calculateAnchorTypeDistribution(data) {
    let sum = 0;
    const distribution = {
        [BRAND.value]: { count: 0, share: 0 },
        [NAKED.value]: { count: 0, share: 0 },
        [OTHER.value]: { count: 0, share: 0 },
    };

    // Calculate counts

    data.reduce((result, item) => {
        const { type } = item;

        switch (type) {
            case BRAND.value: {
                result[BRAND.value].count += 1;
                break;
            }
            case NAKED.value: {
                result[NAKED.value].count += 1;
                break;
            }
            case OTHER.value:
            default: {
                result[OTHER.value].count += 1;
                break;
            }
        }

        // Update sum
        sum += 1;

        return result;
    }, distribution);

    // Calculate shares
    //
    distribution[BRAND.value].share = MathService.getPercent({
        value: distribution[BRAND.value].count,
        total: sum,
    });

    distribution[NAKED.value].share = MathService.getPercent({
        value: distribution[NAKED.value].count,
        total: sum,
    });

    distribution[OTHER.value].share = MathService.getPercent({
        value: distribution[OTHER.value].count,
        total: sum,
    });

    return distribution;
}

/* eslint-enable no-param-reassign */

/* eslint-disable no-param-reassign */
function calculateRefDomainTopRankDistribution(data) {
    let sum = 0;
    const distribution = {
        [RefDomainsTopRankRanges.TOP_10K]: { count: 0, share: 0 },
        [RefDomainsTopRankRanges.TOP_100K]: { count: 0, share: 0 },
        [RefDomainsTopRankRanges.TOP_300K]: { count: 0, share: 0 },
        [RefDomainsTopRankRanges.TOP_1M]: { count: 0, share: 0 },
        [RefDomainsTopRankRanges.OVER_1M]: { count: 0, share: 0 },
    };

    // Calculate counts

    data.reduce((result, item) => {
        const { topRank } = item;

        if (topRank <= 10000) {
            result[RefDomainsTopRankRanges.TOP_10K].count += 1;
        } else if (topRank <= 100000) {
            result[RefDomainsTopRankRanges.TOP_100K].count += 1;
        } else if (topRank <= 300000) {
            result[RefDomainsTopRankRanges.TOP_300K].count += 1;
        } else if (topRank <= 1000000) {
            result[RefDomainsTopRankRanges.TOP_1M].count += 1;
        } else {
            result[RefDomainsTopRankRanges.OVER_1M].count += 1;
        }

        // Update sum
        sum += 1;

        return result;
    }, distribution);

    // Calculate shares

    distribution[RefDomainsTopRankRanges.TOP_10K].share = MathService.getPercent({
        value: distribution[RefDomainsTopRankRanges.TOP_10K].count,
        total: sum,
    });

    distribution[RefDomainsTopRankRanges.TOP_100K].share = MathService.getPercent({
        value: distribution[RefDomainsTopRankRanges.TOP_100K].count,
        total: sum,
    });

    distribution[RefDomainsTopRankRanges.TOP_300K].share = MathService.getPercent({
        value: distribution[RefDomainsTopRankRanges.TOP_300K].count,
        total: sum,
    });

    distribution[RefDomainsTopRankRanges.TOP_1M].share = MathService.getPercent({
        value: distribution[RefDomainsTopRankRanges.TOP_1M].count,
        total: sum,
    });

    distribution[RefDomainsTopRankRanges.OVER_1M].share = MathService.getPercent({
        value: distribution[RefDomainsTopRankRanges.OVER_1M].count,
        total: sum,
    });

    return distribution;
}

/* eslint-enable no-param-reassign */

/* eslint-disable max-len */
export const backlinkProfileOverviewDataSelector = createSelector(
    [
        dashboardTopAnchorsSelectedAnchorTypeSelector,
        dashboardTopAnchorsSortingSettingsSelector,
        dashboardTopRefDomainsSortingSettingsSelector,
        rawBacklinkProfileOverviewDataSelector,
    ],
    (selectedAnchorType, topAnchorsSortingSettings, topRefDomainsSortingSettings, rawData) => {
        const {
            history,
            refDomains,
            refIPs,
            refSubnets,
            topAnchors,
            topRefDomains,
            totalDeletedLinks,
            totalFrameLinks,
            totalImageLinks,
            totalLinks,
            totalNoFollowLinks,
            totalRedirectLinks,
            totalTextLinks,
        } = rawData;

        const calculatedHistory = generateTotalInHistory(history, totalLinks);
        const firstHistoryItem = !isEmpty(calculatedHistory) ? calculatedHistory[0] : Defaults.NOT_AVAILABLE;
        const lastHistoryItem = !isEmpty(calculatedHistory)
            ? calculatedHistory[calculatedHistory.length - 1]
            : Defaults.NOT_AVAILABLE;
        const sortedTopRefDomains = generateTopRefDomains({
            data: topRefDomains,
            sortingSettings: topRefDomainsSortingSettings,
        });

        const sortedAndFilteredTopAnchors = generateTopAnchors({
            data: topAnchors,
            totalLinks,
            selectedAnchorType,
            sortingSettings: topAnchorsSortingSettings,
        });

        return {
            activeRatio:
                totalLinks > 0
                    ? getRatio({ value: totalDeletedLinks, total: totalLinks, inverted: true })
                    : Defaults.NOT_AVAILABLE,
            doFollowRatio:
                totalLinks > 0
                    ? getRatio({ value: totalNoFollowLinks, total: totalLinks, inverted: true })
                    : Defaults.NOT_AVAILABLE,
            history: calculatedHistory,
            historyTotalLinksChangePercent: MathService.diffPercent({
                reference: firstHistoryItem.total,
                value: lastHistoryItem.total,
            }),
            historyTotalDeletedLinks: MathService.sum({ data: calculatedHistory, field: 'deleted' }),
            historyTotalNewLinks: MathService.sum({ data: calculatedHistory, field: 'new' }),
            refDomains,
            refIPs,
            refSubnets,
            topAnchors: sortedAndFilteredTopAnchors,
            topAnchorsTypeDistribution: calculateAnchorTypeDistribution(topAnchors),
            topRefDomains: sortedTopRefDomains,
            topRefDomainsTopRankDistribution: calculateRefDomainTopRankDistribution(topRefDomains),
            totalLinks,
            typeDistribution: [
                {
                    type: LinkTypes.TEXT_LINK,
                    sharePercent: getRatio({ value: totalTextLinks, total: totalLinks }),
                },
                {
                    type: LinkTypes.IMAGE_LINK,
                    sharePercent: getRatio({ value: totalImageLinks, total: totalLinks }),
                },
                {
                    type: LinkTypes.FRAME,
                    sharePercent: getRatio({ value: totalFrameLinks, total: totalLinks }),
                },
                {
                    type: LinkTypes.REDIRECT,
                    sharePercent: getRatio({ value: totalRedirectLinks, total: totalLinks }),
                },
            ],
        };
    },
);
/* eslint-enable max-len */

// ----------------------------------------------------------------------------
// Audience Profile Overview
// ----------------------------------------------------------------------------

/* eslint-disable max-len */
// export const audienceOverviewDataSelector = state => state.data.sections.audienceOverview.data;
// export const audienceOverviewDataFetchingSelector = state => state.data.sections.audienceOverview.fetching;
// export const audienceOverviewDataFetchedSelector = state => state.data.sections.audienceOverview.fetched;

// export const audiencePersonaSelector = createSelector(
//     [audienceOverviewDataSelector],
//     (data) => {
//         const dominantBrowsingLocations = sortWith([descend(prop('value'))])(data.browsingLocationDistribution);
//         const dominantEducations = sortWith([descend(prop('value'))])(data.educationDistribution);
//         const dominantGenders = sortWith([descend(prop('value'))])(data.genderDistribution);

//         return {
//             dominantBrowsingLocationLabel: !isEmpty(dominantBrowsingLocations) && dominantBrowsingLocations[0].value > 0
//                 ? dominantBrowsingLocations[0].label : Defaults.NOT_AVAILABLE,
//             dominantCountry: {
//                 code: Defaults.NOT_AVAILABLE,
//                 label: Defaults.NOT_AVAILABLE
//             },
//             dominantEducationLabel: !isEmpty(dominantEducations) && dominantEducations[0].value > 0
//                 ? dominantEducations[0].label : Defaults.NOT_AVAILABLE,
//             dominantGenderLabel: !isEmpty(dominantGenders) && dominantGenders[0].value > 0
//                 ? dominantGenders[0].label : Defaults.NOT_AVAILABLE
//         };
//     }
// );
/* eslint-enable max-len */

// ================================================================================================
// History data
// ================================================================================================

export const historyDataSelector = state => state.data.history.data;
export const fetchingHistoryDataSelector = state => state.data.history.fetching;

// ================================================================================================
// Announcements data
// ================================================================================================

const announcementsDataSelector = state => state.data.announcements.data;
const fetchingAnnouncementsDataSelector = state => state.data.announcements.fetching;

export const announcementsMessageDataSelector = createSelector([announcementsDataSelector], announcementsData => {
    // TODO: Remove after debugging
    // return {
    //     activeFrom: 0,
    //     activeTo: 0,
    //     btnText: 'Read more',
    //     icon: 'rocket',
    //     messageText: 'We\'ve launched the competitor analysis in KWFinder',
    //     newTab: false,
    //     url: 'http://mangools.com/blog'
    // };

    if (isNil(announcementsData)) {
        return ANNOUNCEMENT_EMPTY_MESSAGE;
    } else {
        return announcementsData;
    }
});

export const announcementMesageVisibilitySelector = createSelector(
    [
        fetchingAnnouncementsDataSelector,
        announcementsMessageDataSelector,
        limitsFetchedSelector,
        topLevelAnnouncementMessageVisibilitySelector,
        newUserMessageVisibilitySelector,
    ],
    (fetchingAnnouncements, announcementsMessageData, fetchedLimits, announcementsVisible, newUserMessageVisible) => {
        if (
            fetchedLimits === true &&
            fetchingAnnouncements === false &&
            announcementsVisible === true &&
            newUserMessageVisible === false
        ) {
            // TODO: Remove after debugging
            // return true;

            // There is an announcement which is active
            if (announcementsMessageData.activeTo > Date.now() / 1000) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    },
);
import React from 'react';
import { bool, func, string } from 'prop-types';
import unavailableImg from 'images/unavailable-white.svg';
import MessageHolder from 'components/messages/MessageHolder';
import Strings from 'constants/Strings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function FailureMessage(props) {
    if (props.visible) {
        return (
            <MessageHolder onClose={props.onClose}>
                <div className="uk-modal-dialog uk-padding-remove fadeInDown animated-once">
                    <button className="mg-close" onClick={props.onClose} type="button">
                        <FontAwesomeIcon icon="times" />
                    </button>
                    <div className="mg-modal-header is-error">
                        <img src={unavailableImg} alt="Oh no" width="100" />
                    </div>
                    <div className="mg-modal-content">
                        <h3 className="font-24 uk-text-bold">{props.header}</h3>
                        {props.details ? (
                            <p className="uk-margin-top uk-margin-bottom color-grey">{props.details}</p>
                        ) : null}
                        <p className="uk-margin-top uk-margin-bottom color-grey">
                            {Strings.messages.failure.footer_sorry}
                        </p>
                    </div>
                </div>
            </MessageHolder>
        );
    } else {
        return null;
    }
}

FailureMessage.propTypes = {
    details: string,
    header: string,
    onClose: func.isRequired,
    visible: bool.isRequired,
};

FailureMessage.defaultProps = {
    details: null,
    header: 'Sorry, something went wrong',
};

export default FailureMessage;

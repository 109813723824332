import React from 'react';
import { bool, func } from 'prop-types';
import MessageHolder from 'components/messages/MessageHolder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function NoConnectionMessage(props) {
    if (props.visible) {
        return (
            <MessageHolder onClose={props.onClose}>
                <div className="uk-modal-dialog uk-padding-remove fadeInDown animated-once">
                    <button className="mg-close" onClick={props.onClose} type="button">
                        <FontAwesomeIcon icon="times" />
                    </button>
                    <div className="mg-modal-header is-error">
                        <FontAwesomeIcon icon="plug" />
                    </div>
                    <div className="mg-modal-content">
                        <h3 className="font-24 uk-text-bold">Connection problem</h3>
                        <p className="uk-margin-top uk-margin-bottom">
                            Looks like you have <strong>lost connection</strong> to the server
                            <br />
                            or the <strong>request was blocked by a 3rd party application</strong>
                            &nbsp;(e.g. browser extension like adBlock).
                        </p>
                    </div>
                </div>
            </MessageHolder>
        );
    } else {
        return null;
    }
}

NoConnectionMessage.propTypes = {
    onClose: func.isRequired,
    visible: bool.isRequired,
};

export default NoConnectionMessage;

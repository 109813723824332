import ActionTypes from 'constants/ActionTypes';

/**
 * Redux action creators for data manipulation
 */

export const requestedMainOverviewDataAction = () => ({
    type: ActionTypes.DATA_MAIN_OVERVIEW_DATA_REQUESTED,
});

export const fetchingMainOverviewDataAction = () => ({
    type: ActionTypes.DATA_MAIN_OVERVIEW_DATA_FETCHING,
});

export const receivedMainOverviewDataAction = payload => ({
    type: ActionTypes.DATA_MAIN_OVERVIEW_DATA_RECEIVED,
    error: false,
    payload,
});

export const errorMainOverviewDataAction = payload => ({
    type: ActionTypes.DATA_MAIN_OVERVIEW_DATA_ERROR,
    error: true,
    payload,
});

export const requestedTrafficOverviewDataAction = () => ({
    type: ActionTypes.DATA_TRAFFIC_OVERVIEW_DATA_REQUESTED,
});

export const fetchingTrafficOverviewDataAction = () => ({
    type: ActionTypes.DATA_TRAFFIC_OVERVIEW_DATA_FETCHING,
});

export const receivedTrafficOverviewDataAction = payload => ({
    type: ActionTypes.DATA_TRAFFIC_OVERVIEW_DATA_RECEIVED,
    error: false,
    payload,
});

export const errorTrafficOverviewDataAction = payload => ({
    type: ActionTypes.DATA_TRAFFIC_OVERVIEW_DATA_ERROR,
    error: true,
    payload,
});

export const requestedCompetitorsOverviewDataAction = () => ({
    type: ActionTypes.DATA_COMPETITORS_OVERVIEW_DATA_REQUESTED,
});

export const fetchingCompetitorsOverviewDataAction = () => ({
    type: ActionTypes.DATA_COMPETITORS_OVERVIEW_DATA_FETCHING,
});

export const receivedCompetitorsOverviewDataAction = payload => ({
    type: ActionTypes.DATA_COMPETITORS_OVERVIEW_DATA_RECEIVED,
    error: false,
    payload,
});

export const errorCompetitorsOverviewDataAction = payload => ({
    type: ActionTypes.DATA_COMPETITORS_OVERVIEW_DATA_ERROR,
    error: true,
    payload,
});

export const requestedTopContentOverviewDataAction = () => ({
    type: ActionTypes.DATA_TOP_CONTENT_OVERVIEW_DATA_REQUESTED,
});

export const fetchingTopContentOverviewDataAction = () => ({
    type: ActionTypes.DATA_TOP_CONTENT_OVERVIEW_DATA_FETCHING,
});

export const receivedTopContentOverviewDataAction = payload => ({
    type: ActionTypes.DATA_TOP_CONTENT_OVERVIEW_DATA_RECEIVED,
    error: false,
    payload,
});

export const errorTopContentOverviewDataAction = payload => ({
    type: ActionTypes.DATA_TOP_CONTENT_OVERVIEW_DATA_ERROR,
    error: true,
    payload,
});

export const requestedAudienceOverviewDataAction = () => ({
    type: ActionTypes.DATA_AUDIENCE_OVERVIEW_DATA_REQUESTED,
});

export const fetchingAudienceOverviewDataAction = () => ({
    type: ActionTypes.DATA_AUDIENCE_OVERVIEW_DATA_FETCHING,
});

export const receivedAudienceOverviewDataAction = payload => ({
    type: ActionTypes.DATA_AUDIENCE_OVERVIEW_DATA_RECEIVED,
    error: false,
    payload,
});

export const errorAudienceOverviewDataAction = payload => ({
    type: ActionTypes.DATA_AUDIENCE_OVERVIEW_DATA_ERROR,
    error: true,
    payload,
});

export const requestedBacklinkProfileOverviewDataAction = () => ({
    type: ActionTypes.DATA_BACKLINK_PROFILE_OVERVIEW_DATA_REQUESTED,
});

export const fetchingBacklinkProfileOverviewDataAction = () => ({
    type: ActionTypes.DATA_BACKLINK_PROFILE_OVERVIEW_DATA_FETCHING,
});

export const receivedBacklinkProfileOverviewDataAction = payload => ({
    type: ActionTypes.DATA_BACKLINK_PROFILE_OVERVIEW_DATA_RECEIVED,
    error: false,
    payload,
});

export const errorBacklinkProfileOverviewDataAction = payload => ({
    type: ActionTypes.DATA_BACKLINK_PROFILE_OVERVIEW_DATA_ERROR,
    error: true,
    payload,
});

export const rehydrationComplete = () => ({
    type: ActionTypes.DATA_REHYDRATATION_COMPLETE,
});

export const requestedHistoryAction = () => ({
    type: ActionTypes.DATA_HISTORY_REQUESTED,
});

export const fetchingHistoryAction = () => ({
    type: ActionTypes.DATA_HISTORY_FETCHING,
});

export const receivedHistoryAction = payload => ({
    type: ActionTypes.DATA_HISTORY_RECEIVED,
    error: false,
    payload,
});

export const errorHistoryAction = payload => ({
    type: ActionTypes.DATA_HISTORY_ERROR,
    error: true,
    payload,
});

export const skippedHistoryAction = () => ({
    type: ActionTypes.DATA_HISTORY_SKIPPED,
});

export const fetchingAnnouncementsAction = () => ({
    type: ActionTypes.DATA_ANNOUNCEMENTS_FETCHING,
});

export const receivedAnnouncementsAction = payload => ({
    type: ActionTypes.DATA_ANNOUNCEMENTS_RECEIVED,
    error: false,
    payload,
});

export const errorAnnouncementsAction = payload => ({
    type: ActionTypes.DATA_ANNOUNCEMENTS_ERROR,
    error: true,
    payload,
});

export const requestedTrafficKeywordsExportAction = ({ includeMetrics, organic }) => ({
    type: ActionTypes.DATA_TRAFFIC_KEYWORDS_EXPORT_REQUESTED,
    error: false,
    payload: {
        includeMetrics,
        organic,
    },
});

export const requestedExportDataAction = ({ includeMetrics, type }) => ({
    type: ActionTypes.DATA_EXPORT_REQUESTED,
    error: false,
    payload: {
        includeMetrics,
        type,
    },
});

export const errorDeleteHistoryAction = payload => ({
    type: ActionTypes.DATA_HISTORY_DELETE_ERROR,
    error: true,
    payload,
});

export const receivedDeleteHistoryAction = () => ({
    type: ActionTypes.DATA_HISTORY_DELETE_RECEIVED,
});

export const requestedDeleteHistoryAction = () => ({
    type: ActionTypes.DATA_HISTORY_DELETE_REQUESTED,
});

export const fetchAllDataAndPrint = () => ({
    type: ActionTypes.DATA_FETCH_ALL_DATA_AND_PRINT,
});

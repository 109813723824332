import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';

const initialState = {
    url: '',
};

const paramsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.PARAMS_SET: {
            return update(state, {
                url: { $set: action.payload.url },
            });
        }
        default: {
            return state;
        }
    }
};

export default paramsReducer;

import { call, spawn, put, select, take, takeLatest } from 'redux-saga/effects';
import DOMService from 'mangools-commons/lib/services/DOMService';
import AuthService from 'mangools-commons/lib/services/AuthService';
import UsercomService from 'mangools-commons/lib/services/UsercomService';

import {
    idSelector,
    userPlanTypeSelector,
    ssoTicketSelector,
    loginTokenSelector,
    accessTokenSelector,
} from 'selectors/userSelectors';

import { colorSchemeSelector } from 'selectors/uiSelectors';

import { currentQuerySelector } from 'selectors/commonSelectors';
import { setAuthTokens, skippedUserAction } from 'actions/userActions';
import { requestedHideAuthTokensAction } from 'actions/routerActions';

import { setNavigatedInternally, showNeedToSignInMessage } from 'actions/uiActions';

import { fetchLimitData, fetchUserData } from 'sagas/userSagas';
import { fetchAfterLoginData } from 'sagas/dataSagas';
import { initAnalytics } from 'sagas/analyticsSagas';
import { redirectToAuth } from 'sagas/routerSagas';

import ActionTypes from 'constants/ActionTypes';
import UserPlanTypes from 'constants/UserPlanTypes';
import { cleanPreviousState } from 'lib/store';

/**
 * App Init flow sagas.
 */

function* afterUserFlow() {
    // 4. Request limit data
    yield spawn(fetchLimitData);

    // 5. Get email and initialize  chat
    const userId = yield select(idSelector);

    yield spawn(UsercomService.initChatWidet, {
        userId,
        apiKey: process.env.USERCOM_CHAT_WIDGET_API_KEY,
        subdomain: process.env.USER_COM_SUBDOMAIN,
    });

    // 6. Setup analytics stuff
    yield spawn(initAnalytics);

    // 7. Now fetch remaining after login data (user's lists, history)
    // will be skipped if we dont have access token
    yield spawn(fetchAfterLoginData);

    // 8. Delete prev. version LocalStorage to keep it clean
    yield spawn(cleanPreviousState);

    // 9. if there are no token params in the query and accessToken is null it means
    // the /system/me endpoint returned null, thus our tokens are invalid and we need to redirect to mangools.com
    const query = yield select(currentQuerySelector);
    const accessToken = yield select(accessTokenSelector);
    const auth = new AuthService(query, { ssoTicket: null, loginToken: null });

    if (auth.hasInvalidStoredTokens(accessToken)) {
        yield call(redirectToAuth);
    }

    // 10. Get plan type and conditionally show sign in message
    const planType = yield select(userPlanTypeSelector);

    if (auth.hasEmptyQueryTokens() && planType === UserPlanTypes.NO_PLAN) {
        yield put(showNeedToSignInMessage());
    }

    yield put(requestedHideAuthTokensAction());
}

function* watchUserFetched() {
    yield takeLatest(ActionTypes.DATA_USER_DATA_RECEIVED, afterUserFlow);
}

function* fetchUser() {
    yield spawn(watchUserFetched);
    yield call(fetchUserData, null, false);
}

function* authFlow() {
    const query = yield select(currentQuerySelector);
    const ssoTicket = yield select(ssoTicketSelector);
    const loginToken = yield select(loginTokenSelector);
    const auth = new AuthService(query, { ssoTicket, loginToken });

    if (auth.hasEmptyQueryTokens()) {
        // if sso_ticket and login_token params are missing e.g. user is logged out
        // we want to show sign in message by calling fetchUserData which triggers afterUserFlow
        yield put(skippedUserAction());
        yield call(afterUserFlow);
    } else if (auth.hasQueryTokens()) {
        // if sso_ticket and login_token params are present
        // save them to store and fetch user
        yield put(
            setAuthTokens({
                ssoTicket: query.sso_ticket,
                loginToken: query.login_token,
            }),
        );

        yield call(fetchUser);
    } else if (!auth.hasStoredTokens()) {
        // if either ssoTicket or loginToken isn't present in the store
        // redirect to auth
        yield call(redirectToAuth);
    } else if (auth.hasStoredTokens()) {
        // if ssoTicket and loginToken are both present in the store fetch the user
        yield call(fetchUser);
    }
}

export function* initFlow() {
    // 1. Grab SSO Ticket if available
    yield take(ActionTypes.ROUTER_LOCATION_CHANGE);

    // 2. Wait for redux persist load
    yield take(ActionTypes.DATA_REHYDRATATION_COMPLETE);

    // 3. Set default color scheme
    const colorScheme = yield select(colorSchemeSelector);

    if (DOMService.isDarkMode(colorScheme)) {
        DOMService.darkModeOn();
    }

    // 4. Auth flow
    yield call(authFlow);
}

/**
 * Internall navigation flow
 */
export function* internalNavigationFlow() {
    yield take(ActionTypes.ROUTER_LOCATION_CHANGE);
    yield take(ActionTypes.ROUTER_LOCATION_CHANGE);
    yield put(setNavigatedInternally());
}

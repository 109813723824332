import { bool, number, shape, string } from 'prop-types';

export default shape({
    activeFrom: number.isRequired,
    activeTo: number.isRequired,
    btnText: string.isRequired,
    icon: string.isRequired,
    messageText: string.isRequired,
    newTab: bool.isRequired,
    url: string.isRequired,
});

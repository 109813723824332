import React, { Component } from 'react';
import classnames from 'classnames';
import { bool, func } from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';

class Overlay extends Component {
    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this);
    }

    shouldComponentUpdate(newProps, newState) {
        return shallowCompare(this, newProps, newState);
    }

    handleClick(e) {
        e.preventDefault();
        this.props.onClick();
    }

    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    render() {
        if (this.props.visibility) {
            const classes = classnames('uk-modal', 'uk-open', 'uk-display-block', {
                'uk-visible-small': this.props.mobile,
            });

            return <div className={classes} role="region" onClick={this.handleClick} />;
        } else {
            return null;
        }
    }
    /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
}

Overlay.propTypes = {
    mobile: bool.isRequired,
    onClick: func.isRequired,
    visibility: bool.isRequired,
};

export default Overlay;

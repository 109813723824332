const ActionTypes = {
    // ========================================================================
    // DATA ACTIONS
    // ========================================================================

    // Redux persist load completed
    DATA_REHYDRATATION_COMPLETE: 'DATA_REHYDRATATION_COMPLETE',

    // Section data
    DATA_MAIN_OVERVIEW_DATA_ERROR: 'DATA_MAIN_OVERVIEW_DATA_ERROR',
    DATA_MAIN_OVERVIEW_DATA_FETCHING: 'DATA_MAIN_OVERVIEW_DATA_FETCHING',
    DATA_MAIN_OVERVIEW_DATA_RECEIVED: 'DATA_MAIN_OVERVIEW_DATA_RECEIVED',
    DATA_MAIN_OVERVIEW_DATA_REQUESTED: 'DATA_MAIN_OVERVIEW_DATA_REQUESTED',

    DATA_TRAFFIC_KEYWORDS_EXPORT_REQUESTED: 'DATA_TRAFFIC_KEYWORDS_EXPORT_REQUESTED',
    DATA_TRAFFIC_OVERVIEW_DATA_ERROR: 'DATA_TRAFFIC_OVERVIEW_DATA_ERROR',
    DATA_TRAFFIC_OVERVIEW_DATA_FETCHING: 'DATA_TRAFFIC_OVERVIEW_DATA_FETCHING',
    DATA_TRAFFIC_OVERVIEW_DATA_RECEIVED: 'DATA_TRAFFIC_OVERVIEW_DATA_RECEIVED',
    DATA_TRAFFIC_OVERVIEW_DATA_REQUESTED: 'DATA_TRAFFIC_OVERVIEW_DATA_REQUESTED',

    DATA_BACKLINK_PROFILE_OVERVIEW_DATA_ERROR: 'DATA_BACKLINK_PROFILE_OVERVIEW_DATA_ERROR',
    DATA_BACKLINK_PROFILE_OVERVIEW_DATA_FETCHING: 'DATA_BACKLINK_PROFILE_OVERVIEW_DATA_FETCHING',
    DATA_BACKLINK_PROFILE_OVERVIEW_DATA_RECEIVED: 'DATA_BACKLINK_PROFILE_OVERVIEW_DATA_RECEIVED',
    DATA_BACKLINK_PROFILE_OVERVIEW_DATA_REQUESTED: 'DATA_BACKLINK_PROFILE_OVERVIEW_DATA_REQUESTED',

    DATA_TOP_CONTENT_OVERVIEW_DATA_ERROR: 'DATA_TOP_CONTENT_OVERVIEW_DATA_ERROR',
    DATA_TOP_CONTENT_OVERVIEW_DATA_FETCHING: 'DATA_TOP_CONTENT_OVERVIEW_DATA_FETCHING',
    DATA_TOP_CONTENT_OVERVIEW_DATA_RECEIVED: 'DATA_TOP_CONTENT_OVERVIEW_DATA_RECEIVED',
    DATA_TOP_CONTENT_OVERVIEW_DATA_REQUESTED: 'DATA_TOP_CONTENT_OVERVIEW_DATA_REQUESTED',

    DATA_AUDIENCE_OVERVIEW_DATA_ERROR: 'DATA_AUDIENCE_OVERVIEW_DATA_ERROR',
    DATA_AUDIENCE_OVERVIEW_DATA_FETCHING: 'DATA_AUDIENCE_OVERVIEW_DATA_FETCHING',
    DATA_AUDIENCE_OVERVIEW_DATA_RECEIVED: 'DATA_AUDIENCE_OVERVIEW_DATA_RECEIVED',
    DATA_AUDIENCE_OVERVIEW_DATA_REQUESTED: 'DATA_AUDIENCE_OVERVIEW_DATA_REQUESTED',

    DATA_COMPETITORS_OVERVIEW_DATA_ERROR: 'DATA_COMPETITORS_OVERVIEW_DATA_ERROR',
    DATA_COMPETITORS_OVERVIEW_DATA_FETCHING: 'DATA_COMPETITORS_OVERVIEW_DATA_FETCHING',
    DATA_COMPETITORS_OVERVIEW_DATA_RECEIVED: 'DATA_COMPETITORS_OVERVIEW_DATA_RECEIVED',
    DATA_COMPETITORS_OVERVIEW_DATA_REQUESTED: 'DATA_COMPETITORS_OVERVIEW_DATA_REQUESTED',

    DATA_USER_SET_UNLEASH_SESSION: 'DATA_USER_SET_UNLEASH_SESSION',

    // User data
    DATA_USER_CHECK_STATUS_ERROR: 'DATA_USER_CHECK_STATUS_ERROR',
    DATA_USER_CHECK_STATUS_SKIPPED: 'DATA_USER_CHECK_STATUS_SKIPPED',
    DATA_USER_DATA_ERROR: 'DATA_USER_DATA_ERROR',
    DATA_USER_DATA_FETCHING: 'DATA_USER_DATA_FETCHING',
    DATA_USER_DATA_RECEIVED: 'DATA_USER_DATA_RECEIVED',
    DATA_USER_LIMIT_DATA_ERROR: 'DATA_USER_LIMIT_DATA_ERROR',
    DATA_USER_LIMIT_DATA_FETCHING: 'DATA_USER_LIMIT_DATA_FETCHING',
    DATA_USER_LIMIT_DATA_RECEIVED: 'DATA_USER_LIMIT_DATA_RECEIVED',
    DATA_USER_LIMIT_DATA_REQUESTED: 'DATA_USER_LIMIT_DATA_REQUESTED',
    DATA_USER_LIMIT_DATA_SKIPPED: 'DATA_USER_LIMIT_DATA_SKIPPED',
    DATA_USER_LOGOUT_ERROR: 'DATA_USER_LOGOUT_ERROR',
    DATA_USER_LOGOUT_RECEIVED: 'DATA_USER_LOGOUT_RECEIVED',
    DATA_USER_LOGOUT_REQUESTED: 'DATA_USER_LOGOUT_REQUESTED',
    DATA_USER_AUTH_TOKENS_RECEIVED: 'DATA_USER_AUTH_TOKENS_RECEIVED',
    DATA_USER_DATA_SKIPPED: 'DATA_USER_DATA_SKIPPED',

    // History data
    DATA_HISTORY_ERROR: 'DATA_HISTORY_ERROR',
    DATA_HISTORY_FETCHING: 'DATA_HISTORY_FETCHING',
    DATA_HISTORY_RECEIVED: 'DATA_HISTORY_RECEIVED',
    DATA_HISTORY_REQUESTED: 'DATA_HISTORY_REQUESTED',
    DATA_HISTORY_SKIPPED: 'DATA_HISTORY_SKIPPED',
    DATA_HISTORY_DELETE_ERROR: 'DATA_HISTORY_DELETE_ERROR',
    DATA_HISTORY_DELETE_REQUESTED: 'DATA_HISTORY_DELETE_REQUESTED',
    DATA_HISTORY_DELETE_RECEIVED: 'DATA_HISTORY_DELETE_RECEIVED',

    // Announcement message data
    DATA_ANNOUNCEMENTS_ERROR: 'DATA_ANNOUNCEMENTS_ERROR',
    DATA_ANNOUNCEMENTS_FETCHING: 'DATA_ANNOUNCEMENTS_FETCHING',
    DATA_ANNOUNCEMENTS_RECEIVED: 'DATA_ANNOUNCEMENTS_RECEIVED',

    // Export
    DATA_EXPORT_REQUESTED: 'DATA_EXPORT_REQUESTED',

    // Print
    DATA_FETCH_ALL_DATA_AND_PRINT: 'DATA_FETCH_ALL_DATA_AND_PRINT',

    // ========================================================================
    // UI ACTIONS
    // ========================================================================

    UI_ALL_CLOSE: 'UI_ALL_CLOSE',
    UI_MESSAGES_ACCESS_DENIED_CLOSE: 'UI_MESSAGES_ACCESS_DENIED_CLOSE',
    UI_MESSAGES_ACCESS_DENIED_SHOW: 'UI_MESSAGES_ACCESS_DENIED_SHOW',
    UI_MESSAGES_FAILURE_CLOSE: 'UI_MESSAGES_FAILURE_CLOSE',
    UI_MESSAGES_FAILURE_SHOW: 'UI_MESSAGES_FAILURE_SHOW',
    UI_MESSAGES_LOGGED_OUT_CLOSE: 'UI_MESSAGES_LOGGED_OUT_CLOSE',
    UI_MESSAGES_LOGGED_OUT_SHOW: 'UI_MESSAGES_LOGGED_OUT_SHOW',
    UI_MESSAGES_NEED_TO_SIGN_IN_CLOSE: 'UI_MESSAGES_NEED_TO_SIGN_IN_CLOSE',
    UI_MESSAGES_NEED_TO_SIGN_IN_SHOW: 'UI_MESSAGES_NEED_TO_SIGN_IN_SHOW',
    UI_MESSAGES_NEED_TO_UPGRADE_ACCOUNT_CLOSE: 'UI_MESSAGES_NEED_TO_UPGRADE_ACCOUNT_CLOSE',
    UI_MESSAGES_NEED_TO_UPGRADE_ACCOUNT_SHOW: 'UI_MESSAGES_NEED_TO_UPGRADE_ACCOUNT_SHOW',
    UI_MESSAGES_NO_CONNECTION_CLOSE: 'UI_MESSAGES_NO_CONNECTION_CLOSE',
    UI_MESSAGES_NO_CONNECTION_SHOW: 'UI_MESSAGES_NO_CONNECTION_SHOW',
    UI_MESSAGES_TOP_LEVEL_ANNOUNCEMENT_CLOSE: 'UI_MESSAGES_TOP_LEVEL_ANNOUNCEMENT_CLOSE',
    UI_MESSAGES_URL_TYPE_INFO_CLOSE: 'UI_MESSAGES_URL_TYPE_INFO_CLOSE',
    UI_MESSAGES_URL_TYPE_INFO_SHOW: 'UI_MESSAGES_URL_TYPE_INFO_SHOW',

    UI_MISC_NAVIGATED_INTERNALLY_SET: 'UI_MISC_NAVIGATED_INTERNALLY_SET',
    UI_MISC_NEW_VERSION_NOTIFICATION_SHOWN_SET: 'UI_MISC_NEW_VERSION_NOTIFICATION_SHOWN_SET',
    UI_MISC_NOTIFICATIONS_CLOSE_ALL: 'UI_MISC_NOTIFICATIONS_CLOSE_ALL',
    UI_MISC_NOTIFICATIONS_ERROR_SHOW: 'UI_MISC_NOTIFICATIONS_ERROR_SHOW',
    UI_MISC_NOTIFICATIONS_INFO_SHOW: 'UI_MISC_NOTIFICATIONS_INFO_SHOW',
    UI_MISC_ONLINE_STATUS_SET: 'UI_MISC_ONLINE_STATUS_SET',
    UI_MISC_COLOR_SCHEME_SET: 'UI_MISC_COLOR_SCHEME_SET',

    // Dropdowns
    UI_DROPDOWNS_EXPORT_CLOSE: 'UI_DROPDOWNS_EXPORT_CLOSE',
    UI_DROPDOWNS_EXPORT_SHOW: 'UI_DROPDOWNS_EXPORT_SHOW',
    UI_DROPDOWNS_HELP_CLOSE: 'UI_DROPDOWNS_HELP_CLOSE',
    UI_DROPDOWNS_HELP_SHOW: 'UI_DROPDOWNS_HELP_SHOW',
    UI_DROPDOWNS_LINKS_CLOSE: 'UI_DROPDOWNS_LINKS_CLOSE',
    UI_DROPDOWNS_LINKS_SHOW: 'UI_DROPDOWNS_LINKS_SHOW',

    // Dashboard
    UI_DASHBOARD_DOMINANT_COLOR_SET: 'UI_DASHBOARD_DOMINANT_COLOR_SET',
    UI_DASHBOARD_RESET: 'UI_DASHBOARD_RESET',
    UI_DASHBOARD_TOP_ANCHORS_SELECTED_ANCHOR_TYPE_SET: 'UI_DASHBOARD_TOP_ANCHORS_SELECTED_ANCHOR_TYPE_SET',
    UI_DASHBOARD_TOP_ANCHORS_SORTING_SETTINGS_SET: 'UI_DASHBOARD_TOP_ANCHORS_SORTING_SETTINGS_SET',
    UI_DASHBOARD_TOP_CONTENT_SORTING_COLUMN_SET: 'UI_DASHBOARD_TOP_CONTENT_SORTING_COLUMN_SET',
    UI_DASHBOARD_TOP_REF_DOMAINS_SORTING_SETTINGS_SET: 'UI_DASHBOARD_TOP_REF_DOMAINS_SORTING_SETTINGS_SET',

    // UI DEFAULTS ACTIONS

    // Modals

    // Panels
    UI_PANELS_APP_CLOSE: 'UI_PANELS_APP_CLOSE',
    UI_PANELS_APP_SHOW: 'UI_PANELS_APP_SHOW',
    UI_PANELS_HISTORY_CLOSE: 'UI_PANELS_HISTORY_CLOSE',
    UI_PANELS_HISTORY_SHOW: 'UI_PANELS_HISTORY_SHOW',

    // ========================================================================
    // OTHER ACTIONS
    // ========================================================================

    // Analytics
    ANALYTICS_TRACK: 'ANALYTICS_TRACK',

    // Params
    PARAMS_SET: 'PARAMS_SET',

    // Router
    ROUTER_LOCATION_CHANGE: '@@router/LOCATION_CHANGE',
    ROUTER_NAVIGATION_BACK_REQUESTED: 'ROUTER_NAVIGATION_BACK_REQUESTED',
    ROUTER_NAVIGATION_REPLACE_REQUESTED: 'ROUTER_NAVIGATION_REPLACE_REQUESTED',
    ROUTER_NAVIGATION_REQUESTED: 'ROUTER_NAVIGATION_REQUESTED',
    ROUTER_AUTH_TOKENS_HIDE_REQUESTED: 'ROUTER_AUTH_TOKENS_HIDE_REQUESTED',
};

export default ActionTypes;

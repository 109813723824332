import React from 'react';
import { string } from 'prop-types';

function Emoji(props) {
    return (
        <span
            className="emoji"
            role="img"
            aria-label={props.label ? props.label : ''}
            aria-hidden={props.label ? 'false' : 'true'}
        >
            {props.symbol}
        </span>
    );
}

Emoji.propTypes = {
    label: string.isRequired,
    symbol: string.isRequired,
};

export default Emoji;

import React, { PureComponent } from 'react';
import { func, string } from 'prop-types';
import { Favicon } from 'mangools-react-components/src';

import UrlService from 'mangools-commons/lib/services/UrlService';

class HistoryPanelItem extends PureComponent {
    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.onClick({
            url: this.props.url,
        });
    }

    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    render() {
        return (
            <div className="mg-results-tr uk-flex cursor-pointer" onClick={this.handleClick} role="listitem">
                <div className="mg-results-td is-80 uk-flex uk-flex-middle uk-flex-center">
                    <Favicon domain={UrlService.getRoot(this.props.url)} size="16" />
                </div>
                <div className="mg-results-td uk-width-8-10 mg-truncate">
                    <span className="font-16 mg-truncate" title={this.props.url}>
                        {this.props.url}
                    </span>
                </div>
            </div>
        );
    }

    /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
}

HistoryPanelItem.propTypes = {
    onClick: func.isRequired,
    url: string.isRequired,
};

export default HistoryPanelItem;

import { call } from 'redux-saga/effects';
import Rollbar from 'config/rollbar';
import isNil from 'ramda/src/isNil';
import ErrorCodes from 'mangools-commons/lib/constants/ErrorCodes';

export function* logError(tag = 'UnknownSaga', payload) {
    if (isNil(payload.status) || payload.status !== ErrorCodes.TOO_MANY_REQUESTS) {
        yield call([Rollbar, Rollbar.error], new Error(`[${tag}]`), payload);
    }
}

export function* logInfo(label, payload = {}) {
    yield call([Rollbar, Rollbar.info], label, payload);
}

export function handleUncaught(generator, errorGenerator) {
    return function* handlingUncaught(...args) {
        let value = null;
        try {
            value = yield call(generator, ...args);
        } catch (e) {
            console.error(`Unhandled error in '${generator.name}'`, e); // eslint-disable-line no-console

            // Call error generator if provided
            if (errorGenerator) {
                yield call(errorGenerator);
            }

            yield call(logError, generator.name, `UNHANDLED: ${e.toString()}`);
        }

        return value;
    };
}
